<template>
    <div>
        <el-steps :active="fileStep" align-center>
            <el-step title="Dosya" description="Yüklemek istediğiniz dosyayı seçin"></el-step>
            <el-step title="Detaylar" description="Dosyaya ait detayları ekleyin"></el-step>
            <el-step title="OCR" description="OCR işleniyor..."></el-step>
            <el-step title="Şifreleniyor" description="Dosyanız şifreleniyor..."></el-step>
            <el-step title="Tamamlandı" description="Dosya yükleme tamamlandı !"></el-step>
        </el-steps>
        <slot v-if="fileStep == 1">
          <el-upload
              class="upload-dms"
              drag
              action="#"
              multiple
              :auto-upload="false"
              :on-change="handleChange">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Dosyayı sürükleyin ya da <em>dosya seçin.</em></div>
          </el-upload>
        </slot>
        <slot v-if="fileStep == 2">
          <el-collapse v-model="activeCollapse" accordion>
            <el-collapse-item :title="file.name" v-for="file,fileKey in files" :name="fileKey" :key="fileKey">
              <el-form label-position="top">
                <el-form-item label="Dosya Adı">
                  <el-input placeholder="Dosya adını yazınız" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="Dosya Açıklaması">
                  <el-input placeholder="Dosya açıklamasını yazınız" v-model="form.description"></el-input>
                </el-form-item>
                <el-form-item label="Klasör Seçimi">
                  <el-cascader
                    v-model="form.folder"
                    :options="options"></el-cascader>
                </el-form-item>
              </el-form>
            </el-collapse-item>
          </el-collapse>
          <div class="justify-flex-end flex">
            <el-button style="width:100px" @click="fileStep = 1" type="default" icon="el-icon-arrow-left">Geri Dön</el-button>
            <el-button style="width:200px" @click="toStep(3)" type="primary" icon="el-icon-check">İlerle</el-button>
          </div>
        </slot>
        <slot v-if="fileStep == 3">
          <div style="text-align:center">
            <img style="width: 700px; margin: 0 auto 20px; display: block;" src="@/assets/images/animate/ocr-a.gif">
            <h4>OCR İşlemi tamamlanıyor, lütfen bekleyiniz.</h4>

            <el-card>
              Dosyanız tamamlanırken işlemlerinize devam edebilirsiniz.  <el-button plain style="width:150px; margin-left:20px" @click.native="fileStep = 5" type="primary" icon="el-icon-arrow-right">Adımları Atla</el-button>
            </el-card>
          </div>
        </slot>
        <slot v-if="fileStep == 4">
          <div style="text-align:center">
            <img style="width: 150px; margin:50px auto 20px; display: block;" src="@/assets/images/animate/encryption-a.gif">
            <h4>Dosyanız şifreleniyor, lütfen bekleyiniz.</h4>

            <el-card>
              Dosyanız tamamlanırken işlemlerinize devam edebilirsiniz.  <el-button plain style="width:150px; margin-left:20px" @click.native="fileStep = 5" type="primary" icon="el-icon-arrow-right">Adımları Atla</el-button>
            </el-card>
          </div>
        </slot>
        <slot v-if="fileStep == 5">
          <div style="text-align:center">
            <img style="width: 220px; margin:50px auto 20px; display: block;" src="@/assets/images/animate/success-a.gif">
            <h4>Dosya yükleme işlemi tamamlandı !</h4>
          </div>
        </slot>
    </div>
  </template>
  
  <script>
import VueContext from 'vue-context';
  
    export default{
      data(){
        return{
          activeCollapse: 0,
          files:null,
          form:{
            description:null,
            name:null,
            folder:null,

          },
          fileStep: 1,
          
        options: [
          {
            value: 'Marketing Materials',
            label: 'Marketing Materials',
            children: [{
              value: 'Product Catalogs',
              label: 'Product Catalogs',
              children: [{
                value: 'Human Resources',
                label: 'Human Resources'
              }, {
                value: 'Project Planning',
                label: 'Project Planning'
              }, {
                value: 'Performance Reviews',
                label: 'Performance Reviews'
              }, {
                value: 'Press Releases',
                label: 'Press Releases'
              }]
            }, {
              value: 'Website Analytics',
              label: 'Website Analytics',
              children: [{
                value: 'Product Catalogs',
                label: 'Product Catalogs'
              }, {
                value: 'Design Specs',
                label: 'Design Specs'
              }]
            }]
          }]
       };
      },
      components:{
        VueContext
      },
      created(){
      },
      computed:{
      },
      methods:{
        toStep(step){
          this.fileStep = 3
            if(fileStep != 5){
              setTimeout( ()=> {
              this.fileStep = 4
              setTimeout( ()=> {
                this.fileStep = 5
                setTimeout( ()=> {
                  this.fileStep = 1
                },2000)
              },4000)
            },4000)
            }
          
        },
        handleChange(file, files) {
          this.files = files;
          this.fileStep = 2
        },
      }
    }
  </script>