<template>
  <div class="login-form">
    <div class="login">
      <h2 class="logo"><img src="../../assets/images/logo.png" width="150"></h2>
      <el-form label-position="top">
        <el-form-item label="E-Mail Adresiniz">
          <el-input v-model="form.email"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="isLoading" @click="forgot" style="width:100%;" type="primary"><b>Sıfırlama Maili Gönder</b></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'Login',
    data(){
      return{
        form:{
          email:null,
        },
        isLoading: false,
      }
    },
    computed:{

    },
    methods:{
      forgot(){
        this.isLoading = true;
        axios.post('/admin/auth/password-reset', this.form)
        .then((response) => {
            this.$store.dispatch('alert/message', { type: 'success', title: 'Sıfırlama Maili Gönderildi. Lütfen E-Postanızı kontrol ediniz. '});
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.isLoading = false;
        })
      }
    },
  }
</script>