<template>
  <div id="app" class="app-layout" :class="[{'mobile' : isMobile}, activePanel, _.toLower($route.name)]">

    <MobilePanelLeft @triggerPanel="activePanel = $event" v-if="isMobile"/>
    <MobilePanelRight v-show="activePanel == 'right-panel'" @triggerPanel="activePanel = $event" v-if="isMobile"/>
    
    <el-container class="is-vertical">
      <div v-show="activePanel == 'left-panel'" @click="activePanel = ''" class="overlay"></div>
      <el-row>
        <Header v-if="auth" :activePanel="activePanel" @triggerPanel="activePanel = $event"></Header>
      </el-row>
        <el-main v-show="activePanel != 'right-panel'">
          <router-view/>
          <el-backtop></el-backtop>
        </el-main>
    </el-container>
  </div>
</template>

<script>
  import Menu from './components/Common/Header/Menu'
  import Header from '@/components/Common/Header';
  import MobilePanelLeft from '@/components/Common/Header/MobilePanelLeft';
  import MobilePanelRight from '@/components/Common/Header/MobilePanelRight';
  export default{
    data(){
      return{
        activePanel: '',
      }
    },
    components:{
      Header,
      Menu,
      MobilePanelLeft,
      MobilePanelRight,
    },
    computed:{
      auth(){
          return this.$auth();
      },
    },
    created(){
        if(this.auth){
          this.$store.dispatch('initLangs');;
        }
    },
    watch:{
      '$route' (to, from){
        this.activePanel = ''
      },
      auth(){
        if(this.auth){
        }
      }
    },
  }
</script>
