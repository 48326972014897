<template>
    <div class="app-file-system">
      <div class="inc">
        <div class="center">
          <el-row :span="12">
            <el-col>
              <h1>Döküman Yönetimi</h1>
            </el-col>
          </el-row>
          <el-tabs @tab-click="handleClick">
            <el-tab-pane>
              <span slot="label"><i class="el-icon-document"></i> Dökümanlar</span>
              <DocumentsDocument/>
            </el-tab-pane>
            <el-tab-pane label="Task">
              <span slot="label"><i class="el-icon-folder"></i> Klasörler</span>
              <DocumentsFolder/>
            </el-tab-pane>
            <el-tab-pane label="Dosya Yükle">
              <span slot="label"><i class="el-icon-plus"></i> Dosya Yükle</span>
              <DocumentsUpload/>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import DocumentsDocument from './document'
    import DocumentsFolder from './folder'
    import DocumentsUpload from './upload'
    export default {
      data() {
        return {
          activeName: 'first',
          defaultProps: {
            children: 'children',
            label: 'label'
          }
        };
      },
      components:{
        DocumentsDocument,
        DocumentsFolder,
        DocumentsUpload
      },
      created(){
        document.querySelector("body").style.setProperty('--pageHeight', window.innerHeight - 120 + 'px');
      },
      methods: {
        handleClick(tab, event) {
        },
        handleNodeClick(data) {
        }
      }
    };
  </script>