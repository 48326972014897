<template>
    <div>
      <el-button type="primary" @click="specialField = true" style="margin-bottom:10px"><i class="el-icon-collection-tag"></i> Depo Oluştur</el-button>
      <el-card class="table-card">
        <h3 class="card-title">
          <div>
            <i class="el-icon-collection-tag"></i>   Depo Yönetimi 
          </div>
        </h3>
        <el-row :gutter="5">
                <el-col :span="3">
                    <el-input size="medium" placeholder="ID Arama" v-model="form.name"></el-input>
                </el-col>
                <el-col :span="3">
                    <el-input size="medium" placeholder="İsim Arama" v-model="form.name"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-button size="medium" type="primary" plain><i class="el-icon-search"></i> Ara</el-button>
                    <el-button size="medium" type="danger" plain><i class="el-icon-delete"></i> Temizle</el-button>
                </el-col>
        </el-row>
        <hr>
        <el-table
        :data="_.shuffle(tableData)"
        align="left"
        style="width: 100%">
        <el-table-column
          label="ID" width="100">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Depo Adı">
          <template slot-scope="scope">
            <i class="el-icon-place"></i>  {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column
          label="Konumu">
          <template slot-scope="scope">
          <i class="el-icon-location"></i> İstanbul
          </template>
        </el-table-column>
        <el-table-column
          label="Organizasyon">
          <template slot-scope="scope">
            <img src="https://avatars.githubusercontent.com/u/88612694?s=64&v=4" width="10">   {{ scope.row.organization }}
          </template>
        </el-table-column>
        <el-table-column
          label="Oluşturan">
          <template slot-scope="scope">
            <div class="flex">
              <img style="border-radius:100%; margin-right:5px" src="https://i.pravatar.cc/321" width="20">   {{ scope.row.from }}
            </div>
          </template>
        </el-table-column>x
        <el-table-column
          label="Tarih">
          <template slot-scope="scope">
           <i class="el-icon-date"></i> {{ scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="Durumu">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="İşlem"
          width="410"
          >
          <template slot-scope="scope">
            <el-button size="medium" type="default" plain><i class="el-icon-c-scale-to-original"></i> Loglar</el-button>
            <el-button size="medium" type="default" plain><i class="el-icon-document"></i> Evraklar</el-button>
            <el-button size="medium" type="default" plain><i class="el-icon-edit"></i> Düzenle</el-button>
            <el-button size="medium" type="danger" plain><i class="el-icon-remove"></i> Sil</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage4"
          :page-sizes="[10,20, 50, 75, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="20">
        </el-pagination>
      </div>
    </el-card>
    
    <el-dialog width="400px" title="Depo Oluştur" :visible.sync="specialField">
      <div>
        <el-form label-position="top">
          <el-form-item label="Depo Adı">
            <el-input placeholder="Depo Adı" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Depo Konumu">
            <el-input placeholder="Depo Konumu" v-model="form.location"></el-input>
          </el-form-item>
          <el-form-item label="Açıklama">
            <el-input placeholder="Açıklama yazın" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="Organizasyon">
              <el-select value="eus" placeholder="Seçiniz">
                <el-option
                  key="Eus Teknoloji"
                  label="Eus Teknoloji"
                  value="eus">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="Aktif/Pasif">
            <el-switch v-model="form.status"> </el-switch>
          </el-form-item>
          <el-button style="width:100%" type="primary"><i class="el-icon-collection-tag"></i> Depoyu Oluştur</el-button>
          <br><br>
        </el-form>
      </div>
    </el-dialog>
    </div>
  </template>
  
  <script>
    export default{
        data(){
          return{
            specialField:false,
            form:{
              name:"",
              desc:'',
              name:'',
              type:'',
              status:true,
            },
            tableData:[
              {
                id:714911,
                status:1,
                name:"Depo 1",
                desc:'Dosya Taşıma A dan B ye',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                type:'Taşıma',
                priority:'Orta',
                from:'John Doe',
                to:'Mia Lee',
              },
              {
                id:714911,
                status:1,
                name:"Depo 2",
                desc:'Dosya Taşıma B den A ya',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                type:'Depo',
                priority:'Orta',
                from:'John Doe',
                to:'Mia Lee',
              },
              {
                id:714911,
                status:1,
                name:"Depo 3",
                desc:'#21334 Dosyanın imhası',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                type:'İmha',
                priority:'Orta',
                from:'John Doe',
                to:'Mia Lee',
              },
              {
                id:714911,
                status:1,
                name:"Depo 4",
                desc:'Dosya imzalaması',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                type:'Taşıma',
                priority:'Orta',
                from:'John Doe',
                to:'Mia Lee',
              },
            ]
          }
        }
    }
  </script>