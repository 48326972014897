<template>
    <div>
      <el-form size="small" label-position="top" v-if="!_.isEmpty(form)">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="Profil Fotoğrafı">
              <div class="profile-c">
                <el-avatar src="https://avatars.githubusercontent.com/u/88612694?s=64&v=4"></el-avatar>
                <el-dropdown>
                  <el-button type="default">
                    Düzenle <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>Fotoğrafı Değiştir</el-dropdown-item>
                    <el-dropdown-item>Fotoğrafı Kaldır</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
          </el-col>
          <h2>Organizasyon Bilgileri</h2>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Organizasyon Adı">
                  <el-input size="medium" autocomplete="off" required placeholder="İsim" value="EUS TEKNOLOJİ"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          <h2>Özel Alanlar</h2>
          <hr>
          <el-col :span="12">
            <el-form-item label="İsim">
              <el-input size="medium" autocomplete="off" required placeholder="İsim" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Soyisim">
              <el-input size="medium" autocomplete="off" required placeholder="Soyisim" v-model="form.surname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Kullanıcı Adı">
              <el-input size="medium" autocomplete="off" required placeholder="Kullanıcı Adı" v-model="form.username"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="E-Mail">
              <el-input size="medium" autocomplete="off" required placeholder="E-Mail" type="email"  v-model="form.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Şirket Adı">
              <el-input size="medium" autocomplete="off" required placeholder="Şirket Adı" v-model="form.company_name"></el-input>
            </el-form-item>
          </el-col>
            <el-col :span="12">
              <el-form-item label="Telefon">
                <vue-tel-input @input="onTelInput" v-bind="telProps"></vue-tel-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Kimlik Numarası">
                <el-input size="medium" onKeyDown="if(this.value.length==11 && event.keyCode!=8) return false;" required placeholder="TC Kimlik" v-model="form.identity"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Doğum Tarihi">
                <el-input size="medium"  required placeholder="Doğum tarihi" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Ülke">
                <el-select v-model="form.country">
                  <el-option :value="null">Seçiniz</el-option>
                  <el-option v-for="(item,key) in countries" :key="key" :value="item.code">{{ item.name }}</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="İlçe">
                <el-input size="medium"  required placeholder="İlçe" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Posta Kodu">
                <el-input size="medium"  required placeholder="Posta Kodu" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Adres">
                <el-input size="medium" required placeholder="Adres" v-model="form.address"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-top:10px">
              <el-form-item>
                <el-button plain style="width:100%" size="large" :loading="loading" @click="edit()" type="primary">
                  <i class="el-icon-circle-check"> </i>
                  İşlemi Tamamla</el-button>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
    </div>
  </template>
  
  <script>
    import { mapState, mapActions } from "vuex";
    import { VueTelInput } from 'vue-tel-input'
    export default{
      name:'Edit',
      data(){
        return{
            id: this.$route.params.id,
            form:{
              name: null,
              surname: null,
              password: null,
              username : null,
              company_name : null,
              sell_limit : null,
              tax : null,
              tax_number : null,
              address : null,
              phone : null,
              phone_country : null,
              email : null,
            },
            telProps:{
              placeholder: "Telefon numaranızı yazınız",
              enabledFlags: true
            },
            loading: false,
        }
      },
      components:{
        VueTelInput,
      },
      created(){
      },
      computed:{
        ...mapState("countries", ["countries"]),
        user(){
          return this.$user();
        }
      },
      methods:{
        onTelInput(formattedNumber, { number, country }) {
          this.form.phone_country = '+' + country.dialCode;
          this.form.phone = number.significant;
        },
        edit(){
         
        }
      }
    }
  </script>