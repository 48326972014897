<template>
   <div class="dc-vw" :class="{ 'collapse': collapse}">
        <div class="dc-prvw">
              <el-button @click="$router.go(-1)" type="default" plain><i class="el-icon-arrow-left"></i> Geri Dön</el-button>
              <img src="@/assets/images/doc.png" alt="">
        </div>
        <div class="dc-r">
          <div class="collapse-btn" @click="collapse = !collapse">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="versions">
            <el-button  @click="versionModal = true"><i class="el-icon-copy-document"></i> Versiyonlar </el-button>
            <el-button><i class="el-icon-plus"></i> Yeni Versiyon Oluştur </el-button>
            
          </div>
          <el-dialog
          title="Versiyonlar"
          :visible.sync="versionModal"
          width="30%"
          :before-close="handleClose">
              <div class="info-item white">
                <div class="left">
                    <div class="icon">
                        <i class="el-icon-document"></i>
                    </div>
                    <div class="text">
                        <h3>Version 1.1</h3>
                    </div>
                </div>
                <div class="button">
                    <el-button type="primary" plain>  <i class="el-icon-view"></i> Göster</el-button>
                </div>
            </div>
            <div slot="footer" class="dialog-footer flex justify-between">
            </div>
          </el-dialog>
          <div class="title">
            <img src="/img/doc.f75c71a8.svg">
            project_planning_report_14562.pdf
          </div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="Ayrıntılar" name="1">
              <p><i class="el-icon-view"></i> Dosyayı Görüntüleyenler</p>
              <div class="avatars" style="margin:10px 0">
                <el-tooltip  class="item" effect="dark" content="Aiden Kim" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/300"></el-avatar></el-tooltip>
                <el-tooltip  class="item" effect="dark" content="Aaron Jones" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/301"></el-avatar></el-tooltip>
                <el-tooltip  class="item" effect="dark" content="Emily Chen" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/302"></el-avatar></el-tooltip>
                <el-tooltip  class="item" effect="dark" content="Aiden Kim" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/300"></el-avatar></el-tooltip>
                <el-tooltip  class="item" effect="dark" content="Aaron Jones" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/351"></el-avatar></el-tooltip>
                <el-tooltip  class="item" effect="dark" content="Emily Chen" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/307"></el-avatar></el-tooltip>
                <el-tooltip  class="item" effect="dark" content="Emily Chen" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/352"></el-avatar></el-tooltip>
              </div>
              <hr>
              <p><i class="el-icon-view"></i> Dosyayı Paylaşılanlar</p>
              <div class="avatars" style="margin:10px 0">
                <el-tooltip  class="item" effect="dark" content="Aiden Kim" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/320"></el-avatar></el-tooltip>
                <el-tooltip  class="item" effect="dark" content="Aaron Jones" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/331"></el-avatar></el-tooltip>
                <el-tooltip  class="item" effect="dark" content="Emily Chen" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/352"></el-avatar></el-tooltip>
              </div>
              <hr>
              <p><i class="el-icon-place"></i>  Dosya Ayrıntıları</p>
              <div class="info">
                <span>Dosya Türü:</span>
                <b>PDF</b>
              </div>
              <div class="info">
                <span>Dosya Boyutu:</span>
                <b>1.5 MB</b>
              </div>
              <div class="info">
                <span>Son Görüntüleyen:</span>
                <b>John Doe</b>
              </div>
              <div class="info">
                <span>Dosyayı Ekleyen:</span>
                <b>John Doe</b>
              </div>
              <div class="info">
                <span>Bulunduğu Dosya:</span>
                <b>Project Planning</b>
              </div>
              <div class="info">
                <span>Dosyanın Konumu:</span>
                <b>Depoda</b>
              </div>
              <div class="info">
                <span>Değiştirilme Tarihi:</span>
                <b>2022-02-12</b>
              </div>
              <div class="info">
                <span>Eklenme Tarihi:</span>
                <b>2022-02-12</b>
              </div>
              <hr>
              <p><i class="el-icon-collection-tag"></i>  Özel Etiketler</p>
              <div class="info">
                <span>Fatura Numarası</span>
                <b>#12312412</b>
              </div>
              <el-button><i class="el-icon-collection-tag"></i> Özel Etiket Ekle</el-button>
            </el-tab-pane>
            <el-tab-pane label="Ekler" name="6">
              <div class="content box direction-column flex">
                <div class=" flex align-center">
                  <div class="avatar">
                    <img style="margin-right:10px" width="25" :src="require('../../assets/images/icons/format/' + 'pdf' + '.svg')">
                  </div>
                  <span>
                    <b>equipment_instructions_63421.pdf</b>
                  </span>
                </div>
                <br>
                <div class="content box flex align-center" style="margin-left:30px" v-for="x,key in 4">
                  <div class="flex align-center">
                    <div class="avatar">
                      <img style="margin-right:10px" width="25" :src="require('../../assets/images/icons/format/' + 'pdf' + '.svg')">
                    </div>
                    <span>
                      <b>Sayfa {{ key + 1  }}</b>
                    </span>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Notlar" name="3">
              <p>Not Ekle</p>
              <el-input size="medium" placeholder="Eklemek istediğiniz notu yazınız"></el-input>
              <el-button plain style="margin-top:10px; width:100%" type="primary"><i class="el-icon-check"></i> Notu Ekle</el-button>
              <br><br>
              <hr>
              <br>
              <div class="content box flex align-center">
                <div class="avatar">
                  <el-avatar style="margin-right:10px" src="https://i.pravatar.cc/303"></el-avatar>
                </div>
                <span>
                  <b>Mia Lee</b>
                  <br>
                  <span class="light">Dosyayı indirdim.</span><br>
                  <small class="light">22-01-2012 14:22</small>
                </span>
              </div>
              <div class="content box flex align-center">
                <div class="avatar">
                  <el-avatar style="margin-right:10px" src="https://i.pravatar.cc/303"></el-avatar>
                </div>
                <span>
                  <b>Emily Chen</b>
                  <br>
                  <span class="light">Dosyanın adını düzenledim.</span><br>
                  <small class="light">22-01-2012 14:22</small>
                </span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Etkinlik" name="2">
              <slot v-for="x in 2">
                <div class="content box flex align-center">
                  <div class="avatar">
                    <el-avatar style="margin-right:10px" src="https://i.pravatar.cc/302"></el-avatar>
                  </div>
                  <span>
                    <b>Aaron Jones</b>
                    <br>
                    <span class="light">Dosyayı görüntüledi.</span><br>
                    <small class="light">22-01-2012 14:22</small>
                  </span>
                </div>
                <div class="content box flex align-center">
                  <div class="avatar">
                    <el-avatar style="margin-right:10px" src="https://i.pravatar.cc/303"></el-avatar>
                  </div>
                  <span>
                    <b>Emily Chen</b>
                    <br>
                    <span class="light">Dosyanın konumunu depoda olarak güncelledi.</span><br>
                    <small class="light">22-01-2012 14:22</small>
                  </span>
                </div>
                <div class="content box flex align-center">
                  <div class="avatar">
                    <el-avatar style="margin-right:10px" src="https://i.pravatar.cc/303"></el-avatar>
                  </div>
                  <span>
                    <b>Mia Lee</b>
                    <br>
                    <span class="light">Dosyayı indirdi.</span><br>
                    <small class="light">22-01-2012 14:22</small>
                  </span>
                </div>
                <div class="content box flex align-center">
                  <div class="avatar">
                    <el-avatar style="margin-right:10px" src="https://i.pravatar.cc/303"></el-avatar>
                  </div>
                  <span>
                    <b>Mia Lee</b>
                    <br>
                    <span class="light">Dosyayı indirdi.</span><br>
                    <small class="light">22-01-2012 14:22</small>
                  </span>
                </div>
              </slot>
            </el-tab-pane>
            <el-tab-pane label="İşlemler" name="4">
              <p>Dosyayı Paylaş</p>
              <el-row :gutter="5">
                <el-col :span="12">
                  <el-button @click="shareModal = true" style="width:100%" type="default" plain><i class="el-icon-user"></i> Kişiyle Paylaş</el-button>
                                      
                </el-col>
                <el-col :span="12">
                  <el-button @click="shareModal = true" style="width:100%" type="default" plain><i class="el-icon-place"></i> Organizasyonla Paylaş</el-button>
                </el-col>
              </el-row>

              <el-dialog
                title="Dosyayı Paylaş"
                :visible.sync="shareModal"
                width="30%"
                :before-close="handleClose">
                <Share></Share>
                <div slot="footer" class="dialog-footer flex justify-between">
                  <el-button type="primary" plain>  <i class="el-icon-link"></i> Bağlantıyı Kopyala</el-button>
                  <el-button type="default" @click="$parent.shareModal = false" plain>  <i class="el-icon-check"></i> Bitti</el-button>
                </div>
                </el-dialog>
              <br>
              <hr>
              <p>Konumunu Güncelle</p>
              <el-row :gutter="5">
                <el-col :span="24">
                  <el-select v-model="value" size="medium" placeholder="Dosya nerede">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                  <el-button plain style="margin-top:10px; width:100%" type="primary"><i class="el-icon-check"></i> Konumunu Güncelle</el-button>
                </el-col>
              </el-row>
              <br>
              <hr>
              <p>Dosyada işlemler</p>
              <el-row :gutter="5">
                <el-col :span="12">
                  <el-button style="width:100%" type="default" plain><i class="el-icon-s-promotion"></i> Dosyayı Transer Et</el-button>
                </el-col>
                <el-col :span="12">
                  <el-button style="width:100%" type="default" plain><i class="el-icon-right"></i> İş Emri Oluştur</el-button>
                </el-col>
              </el-row>
              <br>
              <el-row :gutter="5">
                <el-col :span="12">
                  <el-button style="width:100%" type="default" plain><i class="el-icon-download"></i> Dosyayı İndir</el-button>
                </el-col>
                <el-col :span="12">
                  <el-button style="width:100%" type="default" plain><i class="el-icon-folder"></i> Dosyayı Taşı</el-button>
                </el-col>
              </el-row>
              <br>
              <el-row :gutter="5">
                <el-col :span="12">
                  <el-button style="width:100%" type="default" plain><i class="el-icon-edit"></i> Yeniden Adlandır</el-button>
                </el-col>
                <el-col :span="12">
                  <el-button style="width:100%" type="danger" plain><i class="el-icon-delete"></i> Dosyayı Sil</el-button>
                </el-col>
              </el-row>
              <p>OCR Ve Thumnail</p>
              <el-row :gutter="5">
                <el-col :span="12">
                  <el-button style="width:100%"><i class="el-icon-document"></i> Yeni OCR</el-button>
                </el-col>
                <el-col :span="12">
                  <el-button style="width:100%"><i class="el-icon-document"></i> Yeni Thumbnail</el-button>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
   </div>
</template>
  
  <script>
    import Share from '../../components/Common/Share'
    export default{
      data(){
        return{
          collapse:false,
          activeName:"1",
          versionModal:false,
          shareModal:false,
          options: [{
            value: 'Depo',
            label: 'Depo'
          }],
        }
      },
      components:{
        Share
      },
      created(){
        document.querySelector("body").style.setProperty('--pageHeight', window.innerHeight - 80 + 'px');
      },
    }
  </script>