<template>
  <div class="login-form">
    <div class="login">
      <h2 class="logo"><img src="../../assets/images/logo.png" width="150"></h2>
      <el-form label-position="top">
        <el-form-item label="E-Mail Adresiniz">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="Yeni Şifreniz">
          <el-input type="password" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item @keyup.enter.native="reset" label="Yeni Şifreniz Tekrar">
          <el-input type="password" v-model="form.password_confirmation"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="isLoading" @click="reset" style="width:100%;" type="primary"><b>Giriş Yap</b></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  export default{
    name: 'Login',
    data(){
      return{
        form:{
          email:null,
          password:null,
          password_confirmation:null,
          token:this.$route.query.token,
        },
        isLoading: false,
      }
    },
    created(){
    },
    methods:{
      reset(){
        this.isLoading = true;
        axios.patch('/admin/auth/password-reset', this.form)
        .then((response) => {
            this.$store.dispatch('alert/message', { type: 'success', title: 'Şifreniz Sıfırlandı !'});
            setTimeout( ()=> {
              window.location.href = '/auth/login';
            }, 500)
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.isLoading = false;
        })
      }
    },
  }
</script>