<template>
    <div>
        <el-input v-model="form.mail" placeholder="Kişi ara (Kullanıcı adı ve ya email)"></el-input>
        <hr style="margin:10px 0px">
        <p>Erişimi olan kişiler</p>
        <div class="info-item white">
            <div class="left">
                <div class="icon">
                    <el-avatar style="margin-right:10px" src="https://i.pravatar.cc/320"></el-avatar>
                </div>
                <div class="text">
                    <h3>Mia Lee</h3>
                    <small>mialee@euscreative.com</small>
                    <br>
                </div>
            </div>
            <div class="button">
                <el-button type="danger" plain>  <i class="el-icon-minus"></i> Kaldır</el-button>
            </div>
        </div>
        <div class="avatars" style="margin:10px 0">
          <el-tooltip  class="item" effect="dark" content="Aiden Kim" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/320"></el-avatar></el-tooltip>
          <el-tooltip  class="item" effect="dark" content="Aaron Jones" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/331"></el-avatar></el-tooltip>
          <el-tooltip  class="item" effect="dark" content="Emily Chen" placement="top"><el-avatar style="margin-right:10px" src="https://i.pravatar.cc/352"></el-avatar></el-tooltip>
        </div>
        <hr style="margin:10px 0px">
    </div>
</template>

<script>
    export default{
        data(){
            return{
                form:{
                    mail:null
                }
            }
        }
    }
</script>