<template>
  <div>
    <div class="filter-form">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input size="medium" placeholder="Dosya İçeriğinde Ara" v-model="form.folder_name"></el-input>
        </el-col>
        <el-col :span="4">
          <el-select size="medium" v-model="form.document_type" clearable placeholder="Döküman Türü">
            <el-option
              v-for="item in type"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select size="medium" v-model="form.owner" clearable placeholder="Dosya Sahibi">
            <el-option
              v-for="item in owner"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input size="medium" placeholder="Dosya ID" v-model="form.folder_id"></el-input>
        </el-col>
        <el-col :span="6">
          <div style="visibility:hidden"><span>empty</span></div>
        </el-col>
        <el-col :span="2">
          <el-dropdown style="width:100%">
            <el-button style="width:100%" icon="el-icon-c-scale-to-original">
              Sırala
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>Tarihe Göre Sırala (A-Z)</el-dropdown-item>
              <el-dropdown-item>Tarihe Göre Sırala (Z-A)</el-dropdown-item>
              <el-dropdown-item>Üyeye Göre Sırala (A-Z)</el-dropdown-item>
              <el-dropdown-item>Üyeye Göre Sırala (Z-A)</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>

    </div>

    <el-table
      :data="tableData"
      align="left"
      style="width: 100%">
      <el-table-column
        
        label="Seç" width="50">
        <template>
          <el-checkbox></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        
        label="ID" width="100">
        "folder":'',
        <template slot-scope="scope">
          #{{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column
       label="Dosya Adı">
        <template slot-scope="scope">
          <div @contextmenu.prevent="$refs.menu.open" class="align-center">
            <img style="margin-right:10px" width="25" :src="require('../../assets/images/icons/format/' + scope.row.document_type + '.svg')">
            <b>{{ scope.row.document_name }}</b>
            <vue-context ref="menu">
                <li><a href="#" @click.prevent="onClick(scope.row.document_name)">  <i class="el-icon-share"></i> Paylaş </a></li>
                <li><a href="#" @click.prevent="onClick(scope.row.document_name)">  <i class="el-icon-copy-document"></i> Kopyala </a></li>
                <li><a href="#" @click.prevent="$router.push({ name: 'DocumentsView', params: { id: 22}})">  <i class="el-icon-view"></i> Göster</a></li>
                <li><a href="#" @click.prevent="onClick($event.target.innerText)">  <i class="el-icon-edit"></i> Düzenle</a></li>
                <li><a href="#" @click.prevent="onClick($event.target.innerText)">  <i class="el-icon-download"></i> İndir</a></li>
            </vue-context>
          </div>
        </template>
      </el-table-column>
      <el-table-column 
        label="Ana Klasör">
        <template slot-scope="scope">
          <i style="color:var(--color)" class="el-icon-folder"></i> {{ scope.row.folder }}
        </template>
      </el-table-column>
      <el-table-column
       width="150"
        label="Eklenme Tarihi">
        <template slot-scope="scope">
         <i class="el-icon-date"></i>   {{ scope.row.date_added }}
        </template>
      </el-table-column>
      <el-table-column
      
        width="150"
        label="Döküman Türü">
        <template slot-scope="scope">
            <el-tag type="info" style="text-transform:uppercase">.{{ scope.row.document_type }}</el-tag>
            <el-tag style="margin-left:5px" type="success" v-if="scope.row.status == 2" size="small"><i class="el-icon-check"></i></el-tag>
            <el-tag style="margin-left:5px" type="warning" v-if="scope.row.status == 1" size="small"><i class="el-icon-loading"></i></el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Ekleyen" width="150">
        <template slot-scope="scope">
          <i class="el-icon-user"></i> {{ scope.row.uploader_name }}
        </template>
      </el-table-column>
      <el-table-column
        label="Düzenleyen" width="150">
        <template slot-scope="scope">
          <i class="el-icon-user"></i> {{ scope.row.uploader_name }}
        </template>
      </el-table-column>
      <el-table-column
        label="Son Konumu" width="150">
        <template slot-scope="scope">
          Depoda
        </template>
      </el-table-column>
      <el-table-column
        width="200"
        label="İşlem">
        <template slot-scope="scope">
            <el-button size="small"> <i class="el-icon-download"></i></el-button>
            <el-button size="small" @click="$router.push({ name: 'DocumentsView', params: { id: 22}})"> <i class="el-icon-view"></i></el-button>
            <el-button size="small"> <i class="el-icon-edit"></i></el-button>
            <el-button size="small"> <i class="el-icon-delete"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <div class="block">
        <el-pagination
          :page-sizes="[10, 20, 50, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="100">
        </el-pagination>
      </div>`
    </div>
  </div>
</template>

<script>
  import VueContext from 'vue-context';

  export default{
    data(){
      return{
        owner: [{
          value: 'John Doe',
          label: 'John Doe'
        }],
        type: [{
          value: 'pdf',
          label: 'pdf'
        }, {
          value: 'xml',
          label: 'xml'
        }, {
          value: 'PNG',
          label: 'PNG'
        }, {
          value: 'xls',
          label: 'xls'
        }, {
          value: 'csv',
          label: 'csv'
        }],
        tableData: [
              {
                "id":14562,
                "status":1,
                "status_name":"completed",
                "folder":'Project Files',
                "document_name": "project_planning_report_14562.pdf",
                "date_added": "2022-02-12",
                "document_type": "pdf",
                "uploader_name": "Ali Can"
              },
              {
                "id":22453,
                "status":2,
                "status_name":"completed",
                "folder":'Marketing Campaign',
                "document_name": "accounting_management_guide_22453.txt",
                "date_added": "2022-03-15",
                "document_type": "txt",
                "uploader_name": "Ayse Yilmaz"
              },
              {
                "id":33223,
                "status":2,
                "status_name":"completed",
                "folder":'Invoices',
                "document_name": "budget_presentation_33223.txt",
                "date_added": "2022-01-10",
                "document_type": "txt",
                "uploader_name": "Mehmet Yildiz"
              },
              {
                "id":45632,
                "status":2,
                "status_name":"completed",
                "folder":'Product Design',
                "document_name": "new_hiring_procedure_45632.pdf",
                "date_added": "2022-02-20",
                "document_type": "pdf",
                "uploader_name": "Fatma Aktas"
              },
              {
                "id":63421,
                "status":2,
                "status_name":"completed",
                "folder":'Customer Feedback',
                "document_name": "equipment_maintenance_instructions_63421.pdf",
                "date_added": "2022-03-05",
                "document_type": "pdf",
                "uploader_name": "Ahmet Yilmaz"
              },
              {
                "id":87412,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "confidentiality_agreement_87412.doc",
                "date_added": "2022-04-01",
                "document_type": "doc",
                "uploader_name": "Seda Cakir"
              },
              {
                "id":93242,
                "status":2,
                "status_name":"completed",
                "folder":'Training Materials',
                "document_name": "workplace_safety_rules_93242.doc",
                "date_added": "2022-02-25",
                "document_type": "doc",
                "uploader_name": "Emre Kilic"
              },
              {
                "id":10482,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "sales_report_10482.xls",
                "date_added": "2022-01-18",
                "document_type": "xls",
                "uploader_name": "Zeynep Demir"
              },
              {
                "id":22005,
                "status":2,
                "status_name":"completed",
                "folder":'Invoices',
                "document_name": "monthly_invoices_22005.csv",
                "date_added": "2022-03-01",
                "document_type": "csv",
                "uploader_name": "Burak Ozdemir"
              },
              {
                "id":12312,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "annual_report_12312.pdf",
                "date_added": "2022-02-01",
                "document_type": "pdf",
                "uploader_name": "Nehir Yildiz"
              },
              {
                "id":14562,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "project_planning_report_14562.pdf",
                "date_added": "2022-02-12",
                "document_type": "pdf",
                "uploader_name": "Ali Can"
              },
              {
                "id":22453,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "accounting_management_guide_22453.txt",
                "date_added": "2022-03-15",
                "document_type": "txt",
                "uploader_name": "Ayse Yilmaz"
              },
              {
                "id":33223,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "budget_presentation_33223.txt",
                "date_added": "2022-01-10",
                "document_type": "txt",
                "uploader_name": "Mehmet Yildiz"
              },
              {
                "id":45632,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "new_hiring_procedure_45632.pdf",
                "date_added": "2022-02-20",
                "document_type": "pdf",
                "uploader_name": "Fatma Aktas"
              },
              {
                "id":63421,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "equipment_maintenance_instructions_63421.pdf",
                "date_added": "2022-03-05",
                "document_type": "pdf",
                "uploader_name": "Ahmet Yilmaz"
              },
              {
                "id":87412,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "confidentiality_agreement_87412.doc",
                "date_added": "2022-04-01",
                "document_type": "doc",
                "uploader_name": "Seda Cakir"
              },
              {
                "id":93242,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "workplace_safety_rules_93242.doc",
                "date_added": "2022-02-25",
                "document_type": "doc",
                "uploader_name": "Emre Kilic"
              },
              {
                "id":10482,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "sales_report_10482.xls",
                "date_added": "2022-01-18",
                "document_type": "xls",
                "uploader_name": "Zeynep Demir"
              },
              {
                "id":22005,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "monthly_invoices_22005.csv",
                "date_added": "2022-03-01",
                "document_type": "csv",
                "uploader_name": "Burak Ozdemir"
              },
              {
                "id":12312,
                "status":2,
                "status_name":"completed",
                "folder":'Sales Reports',
                "document_name": "annual_report_12312.pdf",
                "date_added": "2022-02-01",
                "document_type": "pdf",
                "uploader_name": "Nehir Yildiz"
              }
        ],
        form:{
          folder_id: null,
          document_type: null,
          owner:null,
        }
      }
    },
    components:{
      VueContext
    },
    created(){
    },
    computed:{
    },
    methods:{
        onClick (text) {
          console.log(this.$refs)
        }
    }
  }
</script>