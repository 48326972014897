<template>
  <div class="mobile-app-widgets">
  </div>
</template>

<script>
  export default{
    data(){
      return{
      }
    },
    components:{
    },
		methods:{
				triggerPanel(panel){
					this.$emit('triggerPanel', panel)
				}
		}
  }
</script>