<template>
    <div class="color-mode">
        <small>Koyu Mod: </small>
        <el-switch
            v-model="colorMode"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="dark"
            inactive-value="light">
        </el-switch>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                colorMode: localStorage.getItem("colorMode"),
            }
        },
        created(){
            if(!localStorage.getItem("colorMode")){
                localStorage.setItem("colorMode", "light")
                this.colorMode = "light"
            }
            if(this.colorMode == "dark"){
                document.querySelector("body").classList.add('dark-mode-layout');
            }
        },
        watch:{
            colorMode(val){
                if(val == "dark"){
                    document.querySelector("body").classList.add('dark-mode-layout');
                }else{
                    document.querySelector("body").classList.remove('dark-mode-layout');
                    }
                localStorage.setItem("colorMode", val)
            }
        }
    }
</script>

<style lang="scss">
    $dark-mode : #17212b;
    $site-color : #fff;
    $dark-mode-light : #17212b;
    $success : #91ff00;
    $danger : #ff6464;
    body:not(.dark-mode-layout) .dark-mode{
        display: none;
    }
    .dark-mode-layout{
        background: $dark-mode;
        color: #fff;
        .light-mode{
            display: none;
        }
        .app-folder-system .list-folders::-webkit-scrollbar-track-piece{
            background-color: lighten($dark-mode-light, 2%) !important;
        }
        .app-folder-system .folder-content,
        .user-t .user-c .el-tabs .el-tabs__content,
        *{
            &::-webkit-scrollbar{height: 2px !important; width: 4px;}
            &::-webkit-scrollbar-thumb{background-color: var(--color) !important;border: 1px solid  var(--color)  !important;}
            &::-webkit-scrollbar-track-piece{background-color: $dark-mode !important;}
        }
        .dc-vw .dc-r .title,
        .dc-vw{
            border-color: lighten($dark-mode-light, 10%) !important;
        }
        .box,
        .dc-vw .dc-r hr,
        .dc-vw .dc-r .versions,
        .dc-vw .dc-r,
        .dc-vw .dc-prvw{
            background: $dark-mode-light;
            border-color: lighten($dark-mode-light, 10%) !important;
        }
        .el-menu,
        .el-header .header-app-top{
            background: $dark-mode-light;
             .search-input .el-input__inner:focus,
            .el-input-group__prepend,
            .el-input__inner{
                background: $dark-mode-light !important; 
                color: #fff;
                border-color: lighten($dark-mode-light, 10%) !important;
                &:focus{
                    border-color: lighten($dark-mode-light, 10%) !important;
                }
            }
        }
        .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
        .el-menu--horizontal > .el-submenu .el-submenu__title:hover{
            background: none !important;
        }
        .el-avatar{
            background: lighten($dark-mode-light, 10%) !important;
            color: #fff !important;
            i{
                color: #fff !important;
            }
        }
        .el-form-item__label, .el-link.el-link--info{
            color: #fff !important;
        }
        .el-menu.el-menu--horizontal{
            border-color: lighten($dark-mode-light, 10%) !important;
        }
        .el-table td, .el-table th.is-leaf{
            border-color: lighten($dark-mode-light, 10%) !important;
            color: #fff;
        }
        .el-button--danger.is-plain{
            border-color: var(--danger) !important;
            color: #fff !important;
        }
        .el-button--primary.is-plain{
            border-color: var(--color) !important;
            color: #fff !important;
        }
        .el-table tr{
            &:hover td{
                background: lighten($dark-mode-light, 5%) !important;
            }
        }
        .el-menu--popup{
            border-radius: 10px !important;
            border: none;
        }
        .el-collapse .el-collapse-item .el-collapse-item__wrap, .el-collapse .el-collapse-item .el-collapse-item__header{
            border-bottom: 1px solid lighten($dark-mode-light, 15%) !important;
        }
        .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
            border-radius: 10px;
            border-color: lighten($dark-mode-light, 10%) !important;
            background: lighten($dark-mode-light, 2%) !important;
            margin-bottom: 5px;
        }
        .el-button--info,
        .v-context > li > a:focus, .v-context > li > a:hover, .v-context ul > li > a:focus, .v-context ul > li > a:hover,
        .el-input__inner,
        .el-button--default{
            border-color: lighten($dark-mode-light, 10%) !important;
            background: lighten($dark-mode-light, 10%) !important;
            color: #fff !important;
        }
        
        .app-folder-system .list-folders .item.active,
        .app-folder-system .list-folders .item.active:hover,
        .app-folder-system .folder-content .item.active:hover,
        .app-folder-system .folder-content .item.active{
            background: var(--color) !important;
        }
        .user-t .user-c .el-tabs .el-tabs__header.is-left .el-tabs__item,
        .user-t .user-h,
        .app-folder-system .folder-content .item,
        .el-cascader-menu{
            border-color: lighten($dark-mode-light, 10%) !important;
        }
        .el-table--border::after, .el-table--group::after, .el-table::before{
            background: none !important;
        }
        .el-dropdown-menu__item:not(.is-active){
            color: #fff !important;
        }
        .el-header .header-app-top .search-input .autocomplete,
        .el-dropdown-menu__item,
        .info-item,
        .user-t .user-c .el-tabs .el-tabs__content .el-card__header,
        .el-switch:not(.is-checked) .el-switch__core,
        .v-context, .v-context ul,
        .app-folder-system .icon-folders .item.active,
        .app-folder-system .icon-folders .item:hover,
        .app-folder-system .title-item,
        .app-folder-system .list-folders .item:hover,
        .app-folder-system .folder-content .item:hover,
        .el-tag.el-tag--info{
            border-color: lighten($dark-mode-light, 10%) !important;
            background: lighten($dark-mode-light, 5%) !important;
            color: #fff;
            svg{
                fill:#fff !important
            }
        }

        .pagination, .filter-form{
            border-color: lighten($dark-mode-light, 10%) !important;
            background: lighten($dark-mode-light, 2%) !important;
            margin-bottom: 10px !important;
        }
        .el-dropdown-menu,
        .el-alert--info.is-light,
        .user-t .user-h .r-r .tags .tag,
        .vti__input,
        .vue-tel-input,
        .el-dialog,
        .app-folder-system .folder-content,
        .app-folder-system,
        .el-tabs__nav-wrap::after,
        .el-pagination .btn-next, .el-pagination .btn-prev,
        .el-pagination button:disabled,
        .el-pager li,
        .el-cascader__dropdown,
        .el-cascader-panel,
        .el-collapse .el-collapse-item,
        .el-collapse-item__wrap,
        .el-collapse .el-collapse-item .el-collapse-item__header,
        .el-steps,
        .el-upload-dragger,
        .el-table tr,
        .el-table,
        .el-card{
            border-color: lighten($dark-mode-light, 10%) !important;
            background: lighten($dark-mode-light, 2%) !important;
            color: #fff;
        }
        .el-popper[x-placement^=bottom] .popper__arrow,
        .el-popper[x-placement^=bottom] .popper__arrow::after{
            border-bottom-color:lighten($dark-mode-light, 10%) !important;
        }
        .el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover{
            background: lighten($dark-mode-light, 10%) !important;
        }
        .el-card.widget-card .el-card__body .text span{
            opacity: 1;
            color: #97abc2 !important;
        }
        .user-t .user-c .el-tabs .el-tabs__header.is-left .el-tabs__item svg{
            fill: #fff !important;
        }
        .user-t .user-h .profile,
        .el-alert--info.is-light .el-alert__description,
        #app:not(.mobile) .el-menu > .el-menu-item i, #app:not(.mobile) .el-menu > .el-submenu .el-submenu__title i,
        .el-dialog__title,
        .v-context > li > a, .v-context ul > li > a,
        .el-tabs__item:not(.is-active),
        .el-cascader-node__label,
        .el-step__description.is-process,
        .el-step__title.is-process,
        .el-menu > .el-submenu .el-submenu__title, .el-menu .el-menu-item, .el-menu .el-submenu__title{
            color: #fff !important;
            svg{
                fill: #fff;
            }
        }
        .el-card.widget-card.info .icon{ background: #0081ff !important;i{ color: #fff !important; }}
        .el-tag.el-tag--success,
        .el-card.widget-card.success .icon{ background: #76e530 !important;i{ color: #fff !important; }}
        .el-card.widget-card.danger .icon{ background: #ff0000 !important;i{ color: #fff !important; }}
        .el-tag.el-tag--warning,
        .el-card.widget-card.warning .icon{ background: #fe8700 !important;i{ color: #fff !important; }}
        .el-card.widget-card.purple .icon{ background: #8700f9 !important;i{ color: #fff !important; }}
    }
</style>