<template>
  <div>
    <h2>Oturumlar</h2>
    <hr>
    <p>
      Aktif olduğunuz cihazlar listesi
    </p>
    <el-dialog
      title="Cihaz Detayları"
      :visible.sync="locationModal"
      width="30%"
      :before-close="handleClose">
          <div class="info-item white">
            <div class="left">
                <div class="icon">
                    <i class="el-icon-location"></i>
                </div>
                <div class="text">
                    <h3>IP: 125.222.133.222</h3>
                </div>
            </div>
          </div>
          <div class="info-item white">
            <div class="left">
                <div class="icon">
                    <i class="el-icon-location"></i>
                </div>
                <div class="text">
                    <h3>Ülke: Türkiye</h3>
                </div>
            </div>
          </div>
          <div class="info-item white">
            <div class="left">
                <div class="icon">
                    <i class="el-icon-location"></i>
                </div>
                <div class="text">
                    <h3>Konum: İstanbul, Tuzla</h3>
                </div>
            </div>
          </div>
        <br>
    </el-dialog>
    <div class="info-item">
      <div class="left">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 122.88 101.9"><path class="cls-1" d="M3.34,0h116.2a3.35,3.35,0,0,1,3.34,3.34v77a3.35,3.35,0,0,1-3.34,3.34H3.34A3.35,3.35,0,0,1,0,80.32v-77A3.35,3.35,0,0,1,3.34,0Zm43,88.27h30.3c.08,5.24,2.24,9.94,8.09,13.63H38.2c4.68-3.39,8.11-7.51,8.09-13.63Zm-39-83.5H115.56a3,3,0,0,1,3,3V68a3,3,0,0,1-3,3H7.33a3,3,0,0,1-2.95-3V7.72a3,3,0,0,1,3-2.95Z"/></svg>
        </div>
        <div class="text">
          <h3>Windows 10 PC</h3>
          <small>Son Aktiflik: 13-03-2022 13:44</small>
          <br>
        </div>
      </div>
      <div class="button">
        <el-button @click="locationModal = true" type="default" plain>  <i class="el-icon-location"></i> Detaylar</el-button>
        <el-button type="default" plain>  <i class="el-icon-remove"></i> Sonlandır</el-button>
      </div>
    </div>
    <div class="info-item">
      <div class="left">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 70.34 122.88"><path class="cls-1" d="M13.33,0H57A13.37,13.37,0,0,1,70.34,13.33v96.22A13.37,13.37,0,0,1,57,122.88H13.33A13.35,13.35,0,0,1,0,109.54V13.33A13.35,13.35,0,0,1,13.33,0ZM35.16,108.37a5.46,5.46,0,1,1-5.45,5.46,5.47,5.47,0,0,1,5.45-5.46Zm31.34-4.19V16.75H3.83v87.43Z"/></svg>
        </div>
        <div class="text">
          <h3>Iphone 14 Mobile</h3>
          <small>Son Aktiflik: 13-03-2022 13:44</small>
          <br>
        </div>
      </div>
      <div class="button">
        <el-button @click="locationModal = true" type="default" plain>  <i class="el-icon-location"></i> Detaylar</el-button>
        <el-button type="default" plain>  <i class="el-icon-remove"></i> Sonlandır</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    name:'Edit',
    data(){
      return{
          form:{
            old_password:null,
            new_password:null,
            new_again_password:null,
          },
          locationModal:false,
          loading: false,
      }
    },
    created(){
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods:{
      edit(){
        this.loading = true;
        axios.patch('/admin/users/update', this.form)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('auth/userInit');
              this.$store.dispatch('alert/message', { type: 'success', title: 'Güncelleme Başarılı !'});
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      }
    }
  }
</script>