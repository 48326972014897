import { render, staticRenderFns } from "./SecurityCenter.vue?vue&type=template&id=25d2894c&"
import script from "./SecurityCenter.vue?vue&type=script&lang=js&"
export * from "./SecurityCenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports