<template>
  <div>
    <el-row>
      <el-col>
        <div class="top-folder">
            <el-row :gutter="5">
              <el-col :span="8">
                <el-button-group>
                  <el-button @click="view = 'icon'" :type="view == 'icon' ? 'primary' : 'default'" icon="el-icon-files">Icon Görünümü</el-button>
                  <el-button @click="view = 'list'" :type="view == 'list' ? 'primary' : 'default'" icon="el-icon-s-unfold">Liste Görünümü</el-button>
                  <el-button @click="view = 'column'" :type="view == 'column' ? 'primary' : 'default'" icon="el-icon-c-scale-to-original">Column Görünümü</el-button>
                </el-button-group>
              </el-col>
              <el-col :span="16" style="display: flex; justify-content: flex-end;">
                <el-button type="info" v-show="view == 'icon' || view == 'list'" v-if="!_.isEmpty(oldIconFolder) && iconFolder != oldIconFolder[0]" @click="backFolder()" style="margin-left: 10px;" icon="el-icon-arrow-left">Geri Dön</el-button>
                <el-button type="default"  style="margin-left: 10px;" icon="el-icon-folder">Yeni Klasör Aç</el-button>
                <slot v-if="!_.isEmpty(activeFolder)">
                    <el-button type="default"  style="margin-left: 10px;" icon="el-icon-edit">{{ activeFolder.type == 'folder' ? 'Klasörü' : 'Dosyayı' }} Düzenle</el-button>
                    <el-button type="default"  style="margin-left: 10px;" icon="el-icon-copy-document">{{ activeFolder.type == 'folder' ? 'Klasörü' : 'Dosyayı' }} Kopyala</el-button>
                    <el-button type="default" plain  style="margin-left: 10px;" icon="el-icon-view">{{ activeFolder.type == 'folder' ? 'Klasörü' : 'Dosyayı' }} Göster</el-button>
                    <el-button type="primary" plain  style="margin-left: 10px;" icon="el-icon-share">{{ activeFolder.type == 'folder' ? 'Klasörü' : 'Dosyayı' }} Paylaş</el-button>
                    <el-button type="danger" plain style="margin-left: 10px;" icon="el-icon-delete">{{ activeFolder.type == 'folder' ? 'Klasörü' : 'Dosyayı' }} Sil</el-button>
                </slot>
              </el-col>
            </el-row>
        </div>
      </el-col>
    </el-row>
    <br>
    <div class="app-folder-system" v-if="view == 'column'">
      <div class="folder-content" v-for="folder,folderKey in folders"  @contextmenu.prevent="$refs.openFolder.open($event, { ...folder });">
        <div class="item"  @contextmenu.prevent="$refs.menu.open($event, { ...item });activeFolder = item" v-for="item in folder" :class="{ active : activeFolder == item}" @click="(item.type == 'folder' ? openFolder(item, folderKey) : ''); activeFolder = item" @dblclick="(item.type != 'folder' ? openDocument(item, folderKey) : '')">
          <div class="col">
            <div class="name">
              <img style="margin-right:10px" width="25" :src="require('../../assets/images/icons/format/' + item.type + '.svg')">
                <span>
                  {{ item.name }}
                </span>
            </div>
          </div>
          <div class="col">
          </div>
          <div class="col">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="app-folder-system icon" v-if="view == 'icon'">
      <div class="icon-folders">
        <div class="item" v-if="!_.isEmpty(oldIconFolder) && iconFolder != oldIconFolder[0]" @dblclick="backFolder()">
            <div class="folder-img">
              <small>...</small>
              <img :src="require('../../assets/images/icons/format/folder.svg')">
            </div>
            <span>
             ...
            </span>
        </div>
        <div class="item" @contextmenu.prevent="$refs.menu.open($event, { ...item });activeFolder = item"  v-for="item,itemKey in iconFolder" :class="{ active : activeFolder == item}" @dblclick="(item.type == 'folder' ? openIconFolder(item, itemKey) : openDocument(item, itemKey));" @click="activeFolder = item">
            <div class="folder-img">
              <small v-if="item.type == 'folder'">{{ _.size(item.parent_folder) }}</small>
              <img :src="require('../../assets/images/icons/format/' + item.type + '.svg')">
            </div>
            <span>
              {{ item.name }}
            </span>
        </div>
        <slot v-if="_.isEmpty(iconFolder)">
          <div class="empty-folder" @contextmenu.prevent="$refs.openFolder.open($event, {  });">
            <img width="100" src="@/assets/images/icons/format/folder-empty.svg">
            Klasör Boş
          </div>
        </slot>
      </div>
    </div>

    
    <div class="app-folder-system list" v-if="view == 'list'">
      <div class="title-item">
        <div class="col name">
          Dosya Adı
        </div>
        <div class="col">
          Eklenme Tarihi
        </div>
        <div class="col">
          Değiştirme Tarihi
        </div>
        <div class="col">
          Sahip
        </div>
        <div class="col">
          Son Değiştiren
        </div>
        <div class="col">
        </div>
      </div>
      <div class="list-folders">
        <div class="item" v-if="!_.isEmpty(oldIconFolder) && iconFolder != oldIconFolder[0]" @dblclick="backFolder()">
            <div class="col name">
              <div class="folder">
                <div class="folder-img">
                  <img :src="require('../../assets/images/icons/format/folder.svg')">
                  <span style="margin-left:15px;position: relative; top: -10px;">...</span>
                </div>
                <span>
                </span>
              </div>
            </div>
            <div class="col">
            </div>
            <div class="col">
            </div>
            <div class="col">
            </div>
            <div class="col">
            </div>
            <div class="col">
              <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <div class="item" @contextmenu.prevent="$refs.menu.open($event, { ...item });activeFolder = item"  v-for="item,itemKey in iconFolder" :class="{ active : activeFolder == item}" @dblclick="(item.type == 'folder' ? openIconFolder(item, itemKey) : openDocument(item, itemKey));" @click="activeFolder = item">
            <div class="col name">
              <div class="folder">
                <div class="folder-img">
                  <small v-if="item.type == 'folder'">{{ _.size(item.parent_folder) }}</small>
                  <img :src="require('../../assets/images/icons/format/' + item.type + '.svg')">
                </div>
                <span>
                  {{ item.name }}
                </span>
              </div>
            </div>
            <div class="col">
              <i class="el-icon-date"></i>  2022-02-12 
            </div>
            <div class="col">
              <i class="el-icon-date"></i>  2022-02-12 
            </div>
            <div class="col">
              {{ item.owner }}
            </div>
            <div class="col">
              {{ item.owner }}
            </div>
            <div class="col">
              <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <slot v-if="_.isEmpty(iconFolder)">
          <div class="empty-folder" @contextmenu.prevent="$refs.openFolder.open($event, {  });">
            <img width="100" src="@/assets/images/icons/format/folder-empty.svg">
            Klasör Boş
          </div>
        </slot>
      </div>
    </div>


    <vue-context ref="openFolder">
        <template slot-scope="item" v-if="item.data">
            <li><a href="#" @click.prevent="backFolder()">  <i class="el-icon-folder"></i> Yeni Klasör Aç </a></li>
        </template>
    </vue-context>
    <vue-context ref="menu">
        <template slot-scope="item" v-if="item.data">
          
            <li><a href="#" @click.prevent="(item.data.type == 'folder' ? openIconFolder(item.data, item.data.id) : '');">  <i class="el-icon-view"></i> Aç </a></li>
            <li><a href="#" @click.prevent="backFolder()">  <i class="el-icon-arrow-left"></i> Geri Dön </a></li>
            <li><a href="#" @click.prevent="onClick($event.target.innerText)">  <i class="el-icon-folder"></i> Bir Üst Klasöre Taşı</a></li>
            <li><a href="#" @click.prevent="onClick(scope.row.document_name)">  <i class="el-icon-copy-document"></i> Kopyala </a></li>
            <li><a href="#" @click.prevent="onClick(scope.row.document_name)">  <i class="el-icon-copy-document"></i> Yeniden Adlandır </a></li>
            <li><a href="#" @click.prevent="onClick($event.target.innerText)">  <i class="el-icon-edit"></i> Düzenle</a></li>
            <li><a href="#" @click.prevent="onClick($event.target.innerText)">  <i class="el-icon-download"></i> İndir</a></li>
            <li><a href="#" @click.prevent="onClick($event.target.innerText)">  <i class="el-icon-delete"></i> Sil</a></li>
        </template>
    </vue-context>
  </div>
</template>

<script>
  import VueContext from 'vue-context';

  export default{
    data(){
      return{
        view: localStorage.getItem("folderView"),
        folderContext:false,
        oldIconFolder:[],
        iconFolder:[
            {
              name: 'Marketing Materials',
              type:'folder',
              id: 'MM-001',
              owner: 'David Kim',
              total: 6,
              parent_folder: [
                {
                  name: 'workplace_safety_rules_93242.doc',
                  type:'doc',
                  id: 'FS-0012',
                  owner: 'Emily Chen',
                  total: 12,
                },  
                {
                  name: 'Product Catalogs',
                  type:'folder',
                  id: 'PC-001',
                  owner: 'Aaron Jones',
                  total: 9,
                  parent_folder: [
                      {
                        name: 'project_planning_report_14562.pdf',
                        type:'pdf',
                        id: 'FS-0021',
                        owner: 'Emily Chen',
                        total: 12,
                      },
                      {
                        name: 'project_planning_report_14562.pdf',
                        type:'pdf',
                        id: 'FS-01201',
                        owner: 'Emily Chen',
                        total: 12,
                      },
                      {
                        name: 'project_planning_report_14562.pdf',
                        type:'pdf',
                        id: 'FS-02101',
                        owner: 'Emily Chen',
                        total: 12,
                      },]
                },
                {
                  name: 'project_planning_report_14562.pdf',
                  type:'pdf',
                  id: 'FS-002131',
                  owner: 'Emily Chen',
                  total: 12,
                },
              ]
            },
            {
              name: 'Human Resources',
              type:'folder',
              id: 'HR-001',
              owner: 'Thomas Lee',
              total: 8,
              parent_folder: []
            },
            {
              name: 'Project Planning',
              type:'folder',
              id: 'PP-001151',
              owner: 'Samantha Green',
              total: 4,
              parent_folder: [
              {
                name: 'Performance Reviews',
                type:'folder',
                id: 'PR-12312',
                owner: 'Elijah Kim',
                total: 8,
                parent_folder: []
              },
              {
                name: 'Press Releases',
                type:'folder',
                id: 'PRE-12312',
                owner: 'Abigail Park',
                total: 3,
                parent_folder: []
              },
              {
                name: 'Website Analytics',
                type:'folder',
                id: 'WA-222',
                owner: 'Aiden Kim',
                total: 4,
                parent_folder: []
              },]
            },
            {
              name: 'workplace_safety_rules_93242.doc',
              type:'doc',
              id: 'FS-001521',
              owner: 'Emily Chen',
              total: 12,
            },
            {
              name: 'Product Catalogs',
              type:'folder',
              id: 'PC-0011512',
              owner: 'Aaron Jones',
              total: 9,
              parent_folder: []
            },
            {
              name: 'project_planning_report_14562.pdf',
              type:'pdf',
              id: 'FS-00151251',
              owner: 'Emily Chen',
              total: 12,
            },
            {
              name: 'Design Specs',
              type:'folder',
              id: 'DS-001',
              owner: 'Sophia Lee',
              total: 5,
              parent_folder: []
            },
            {
              name: 'equipment_maintenance_instructions_63421.pdf',
              type:'pdf',
              id: 'FS-001',
              owner: 'Emily Chen',
              total: 12,
            },
            {
              name: 'Research Data',
              type:'folder',
              id: 'RD-001',
              owner: 'Daniel Kim',
              total: 11,
              parent_folder: []
            },
            {
              name: 'Recruitment Materials',
              type:'folder',
              id: 'RM-001',
              owner: 'Mia Lee',
              total: 6,
              parent_folder: []
            },
            {
              name: 'Performance Reviews',
              type:'folder',
              id: 'PR-001',
              owner: 'Elijah Kim',
              total: 8,
              parent_folder: []
            },
            {
              name: 'Press Releases',
              type:'folder',
              id: 'PRE-001',
              owner: 'Abigail Park',
              total: 3,
              parent_folder: []
            },
            {
              name: 'Website Analytics',
              type:'folder',
              id: 'WA-001',
              owner: 'Aiden Kim',
              total: 4,
              parent_folder: [],}
        ],
        folders:{
          0:[
            {
              name: 'Marketing Materials',
              type:'folder',
              id: 'MM-001',
              owner: 'David Kim',
              total: 6,
              parent_folder: [
                {
                  name: 'workplace_safety_rules_93242.doc',
                  type:'doc',
                  id: 'FS-0012',
                  owner: 'Emily Chen',
                  total: 12,
                },  
                {
                  name: 'Product Catalogs',
                  type:'folder',
                  id: 'PC-001',
                  owner: 'Aaron Jones',
                  total: 9,
                  parent_folder: [
                      {
                        name: 'project_planning_report_14562.pdf',
                        type:'pdf',
                        id: 'FS-0021',
                        owner: 'Emily Chen',
                        total: 12,
                      },
                      {
                        name: 'project_planning_report_14562.pdf',
                        type:'pdf',
                        id: 'FS-01201',
                        owner: 'Emily Chen',
                        total: 12,
                      },
                      {
                        name: 'project_planning_report_14562.pdf',
                        type:'pdf',
                        id: 'FS-02101',
                        owner: 'Emily Chen',
                        total: 12,
                      },]
                },
                {
                  name: 'project_planning_report_14562.pdf',
                  type:'pdf',
                  id: 'FS-002131',
                  owner: 'Emily Chen',
                  total: 12,
                },
              ]
            },
            {
              name: 'Human Resources',
              type:'folder',
              id: 'HR-001',
              owner: 'Thomas Lee',
              total: 8,
              parent_folder: []
            },
            {
              name: 'Project Planning',
              type:'folder',
              id: 'PP-001151',
              owner: 'Samantha Green',
              total: 4,
              parent_folder: [
              {
                name: 'Performance Reviews',
                type:'folder',
                id: 'PR-12312',
                owner: 'Elijah Kim',
                total: 8,
                parent_folder: []
              },
              {
                name: 'Press Releases',
                type:'folder',
                id: 'PRE-12312',
                owner: 'Abigail Park',
                total: 3,
                parent_folder: []
              },
              {
                name: 'Website Analytics',
                type:'folder',
                id: 'WA-222',
                owner: 'Aiden Kim',
                total: 4,
                parent_folder: []
              },]
            },
            {
              name: 'workplace_safety_rules_93242.doc',
              type:'doc',
              id: 'FS-001521',
              owner: 'Emily Chen',
              total: 12,
            },
            {
              name: 'Product Catalogs',
              type:'folder',
              id: 'PC-0011512',
              owner: 'Aaron Jones',
              total: 9,
              parent_folder: []
            },
            {
              name: 'project_planning_report_14562.pdf',
              type:'pdf',
              id: 'FS-00151251',
              owner: 'Emily Chen',
              total: 12,
            },
            {
              name: 'Design Specs',
              type:'folder',
              id: 'DS-001',
              owner: 'Sophia Lee',
              total: 5,
              parent_folder: []
            },
            {
              name: 'equipment_maintenance_instructions_63421.pdf',
              type:'pdf',
              id: 'FS-001',
              owner: 'Emily Chen',
              total: 12,
            },
            {
              name: 'Research Data',
              type:'folder',
              id: 'RD-001',
              owner: 'Daniel Kim',
              total: 11,
              parent_folder: []
            },
            {
              name: 'Recruitment Materials',
              type:'folder',
              id: 'RM-001',
              owner: 'Mia Lee',
              total: 6,
              parent_folder: []
            },
            {
              name: 'Performance Reviews',
              type:'folder',
              id: 'PR-001',
              owner: 'Elijah Kim',
              total: 8,
              parent_folder: []
            },
            {
              name: 'Press Releases',
              type:'folder',
              id: 'PRE-001',
              owner: 'Abigail Park',
              total: 3,
              parent_folder: []
            },
            {
              name: 'Website Analytics',
              type:'folder',
              id: 'WA-001',
              owner: 'Aiden Kim',
              total: 4,
              parent_folder: []
            },
          ],
        },
        activeFolder:{

        }
      }
    },
    created(){
      if(!localStorage.getItem("folderView")){
          localStorage.setItem("folderView", "icon")
          this.view = "icon"
      }
    },
    watch:{
        view(val){
            localStorage.setItem("folderView", val)
        }
    },
    components:{
      VueContext
    },
    mounted(){
        document.querySelector("body").style.setProperty('--pageHeight', window.innerHeight - 120 + 'px');
    },
    computed:{
    },
    methods:{
        backFolder(){
          this.iconFolder = _.last(this.oldIconFolder)
          this.activeFolder = {}
          if(this.oldIconFolder.length > 1){
            this.oldIconFolder.splice(-1);
          }
        },
        openDocument(item,key){
          this.$router.push({ name: 'DocumentsView', params: { id: key }})
        },
        openIconFolder(item,key){
          this.oldIconFolder.push(this.iconFolder)
          this.iconFolder = item.parent_folder
          this.activeFolder = {}
          console.log(this.$refs)
        },  
        openFolder(item, key){
          this.folders[(parseInt(key) + 1)] = item.parent_folder
          this.$forceUpdate();
        },
        onClick (text) {
          
        }
    }
  }
</script>