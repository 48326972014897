<template>
    <div class="form-iframe">
        <iframe width="100%" height="100%" src="https://opnform.com/forms/create/guest" frameborder="0"></iframe>
    </div>
</template>


<script>
    export default{
      mounted(){
          document.querySelector("body").style.setProperty('--iframePage', window.innerHeight + 'px');
      },
    }
</script>
<style lang="scss">
    .form-iframe{
        height: var(--iframePage);
        width: 100%;
    }
</style>