<template>
  <el-card class="filter-card" :class="{ 'collapsed' : !visible}">
    <div slot="header" class="clearfix">
      <span><i class="el-icon-search"></i> Arama Yapın</span>
      <el-button @click="visible = !visible" style="float: right; padding: 0" type="text">
        <slot v-if="visible">
         <i class="el-icon-arrow-up"></i> Bölümü Gizle
        </slot>
        <slot v-else>
          <i class="el-icon-arrow-down"></i> Bölümü Aç
        </slot>
      </el-button>
    </div>
    <el-collapse-transition>
          <slot v-if="visible" name="content"></slot>
    </el-collapse-transition>
  </el-card>
</template>

<script>
  export default {
      name: 'FilterForm',
      props:['collapse'],
      data(){
        return{
          visible: !this.isMobile
        }
      }
  }
</script>

