<template>
  <div>
    <h2>Dosyalarım</h2>
    <hr>
    Sisteme yüklemiş olduğunuz dosyaların bilgisi burada yer almaktadır.
    <br><br>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Product Catalogs</span>
      </div>
      <div class="info-item">
        <div class="left">
          <div class="icon">
            <img width="25" src="@/assets/images/icons/format/pdf.svg" style="margin-right: 10px;">
          </div>
          <div class="text">
            <h3>project_planning_report_14562.pdf </h3>
            <small>Eklenme Tarihi: 12-02-2023 13:22</small>
          </div>
        </div>
        <div class="button">
          <el-button type="default" plain>  <i class="el-icon-view"></i> Dosyayı Gör</el-button>
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <div class="icon">
            <img width="25" src="@/assets/images/icons/format/doc.svg" style="margin-right: 10px;">
          </div>
          <div class="text">
            <h3>project_planning_report_25562.doc </h3>
            <small>Eklenme Tarihi: 12-02-2023 13:22</small>
          </div>
        </div>
        <div class="button">
          <el-button type="default" plain>  <i class="el-icon-view"></i> Dosyayı Gör</el-button>
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <div class="icon">
            <img width="25" src="@/assets/images/icons/format/xls.svg" style="margin-right: 10px;">
          </div>
          <div class="text">
            <h3>project_planning5562.xls </h3>
            <small>Eklenme Tarihi: 16-02-2023 16:22</small>
          </div>
        </div>
        <div class="button">
          <el-button type="default" plain>  <i class="el-icon-view"></i> Dosyayı Gör</el-button>
        </div>
      </div>
    </el-card>
    <br>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Human Resources</span>
      </div>
      <div class="info-item">
        <div class="left">
          <div class="icon">
            <img width="25" src="@/assets/images/icons/format/doc.svg" style="margin-right: 10px;">
          </div>
          <div class="text">
            <h3>workplace_safety_rules_93242.doc </h3>
            <small>Eklenme Tarihi: 12-04-2023 15:51</small>
          </div>
        </div>
        <div class="button">
          <el-button type="default" plain>  <i class="el-icon-view"></i> Dosyayı Gör</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default{
    name:'Edit',
    data(){
      return{
          form:{
            old_password:null,
            new_password:null,
            new_again_password:null,
          },
          loading: false,
      }
    },
    created(){
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods:{
      edit(){
        this.loading = true;
        axios.patch('/admin/users/update', this.form)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('auth/userInit');
              this.$store.dispatch('alert/message', { type: 'success', title: 'Güncelleme Başarılı !'});
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      }
    }
  }
</script>