<template>
  <div>
    <h2>Organizasyon Davet Linki</h2>
    <hr>
    <h4>Özel davet linki ile kullanıcıları bu organizasyona davet edin.</h4>
    <el-button type="info"> <i class="el-icon-share"></i> Davet Linki Oluştur</el-button>
    <br>
    <h2>Organizasyon Verilerini Dışa Aktar</h2>
    <hr>
    <h4>Sistemdeki hareketlerini log olarak indirmenizi sağlar.</h4>
    <el-button type="info"> <i class="el-icon-s-data"></i> Dışa Aktar</el-button>

    <br><br>
    <h2>Organizasyon Pasif Et</h2>
    <hr>
    <h4>Organizasyonu pasif ettiğinizde dosyalarınız gizli kalır.</h4>
    <el-button type="danger" plain> <i class="el-icon-video-pause"></i> Pasif Et</el-button>
    <br><br>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>İzinler</span>
      </div>
      <div class="info-item white">
        <div class="left">
          <div class="text">
            <h3>Organizasyona Herkes Katılabilir mi?</h3>
            <small>Organizasyona kimlerin katılabileceğine izin verin</small>
            <br>
          </div>
        </div>
        <div class="icon">
          <el-switch style="margin-top:5px" v-model="notify.watch_4"></el-switch>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default{
    name:'Edit',
    data(){
      return{
          id: this.$route.params.id,
          form:{
            name: null,
            surname: null,
            password: null,
            username : null,
            company_name : null,
            sell_limit : null,
            tax : null,
            tax_number : null,
            address : null,
            phone : null,
            email : null,
          },
          notify:{

          },
          loading: false,
      }
    },
    created(){
      this.form.name = this.user.name;
      this.form.surname = this.user.surname;
      this.form.username = this.user.username;
      this.form.company_name = this.user.company_name;
      this.form.sell_limit = this.user.sell_limit;
      this.form.tax = this.user.tax;
      this.form.tax_number = this.user.tax_number;
      this.form.address = this.user.address;
      this.form.phone = this.user.phone;
      this.form.email = this.user.email;
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods:{
      edit(){
        this.loading = true;
        axios.patch('/admin/users/update', this.form)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('auth/userInit');
              this.$store.dispatch('alert/message', { type: 'success', title: 'Güncelleme Başarılı !'});
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      }
    }
  }
</script>