<template>
   <div class="app-user-t">
    <div class="user-t">
      <div class="user-h">
        <div class="l-r">
          <div class="profile">
              <el-avatar src="https://i.pravatar.cc/321"></el-avatar>
              <div class="title">
                <p>John Doe</p>
                <span>johndoe</span>
              </div>
          </div>
          <div class="item" style="margin-left:20px">
            <div><i class="el-icon-user"></i> Personel Hesabı</div>
            <div>
              <i class="el-icon-location"></i>
              İstanbul, Türkiye
            </div>
          </div>
          <div class="item" style="margin-left:20px">
            <div><i class="el-icon-phone"></i> +90 502 002 22 11</div>
            <div>
              <i class="el-icon-message"></i>
              johndoe@euscreative.com
            </div>
          </div>
        </div>
        <div class="r-r">
          <div class="item validations">
            <div class="tags">
              <div class="tag success">
                  <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 466 512.14"><path d="M232.2 0c86.89 55.08 165.39 81.13 232.75 74.98 11.77 237.97-76.11 378.5-231.86 437.16C82.7 457.24-6.26 322.75.34 71.36 79.42 75.5 157.01 58.41 232.2 0zm-.22 195c13.4-13.97 22.78-26.05 43.43-28.42 38.76-4.46 74.39 35.22 54.82 74.29-5.57 11.13-16.91 24.35-29.45 37.34-13.77 14.25-29.01 28.22-39.69 38.81l-29.11 28.88-24.04-23.17c-28.94-27.87-76.12-62.94-77.67-106.4-1.1-30.44 22.92-49.94 50.55-49.58 24.7.32 35.08 12.61 51.16 28.25zm.37-155.36c72.06 45.67 141.22 71.35 197.09 66.24 9.76 197.35-67.19 317.97-196.33 366.61C108.37 426.97 30.53 311.37 36 102.89c72.38 3.79 138.71-18.47 196.35-63.25z"/></svg>
                  </div>
                  <div class="title">
                    <b> Aktif</b>
                    <small>G2FA Koruma</small>
                  </div>
              </div>
              <div class="tag success">
                  <div class="icon">
                    <svg shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 408 512.14"><path d="M57.83 0h189.25c15.96 0 30.51 6.51 40.89 16.96 10.52 10.54 16.93 25.02 16.93 40.89v33.28l-.1-.06-18.4-11.67V57.3H18.42v374.51H286.4v-28.79l12.96-.08c1.87-.7 3.72-1.43 5.54-2.17v53.54c0 15.96-6.49 30.52-16.93 40.88-10.55 10.55-25.02 16.95-40.89 16.95H57.83c-15.96 0-30.52-6.49-40.89-16.95C6.41 484.67 0 470.2 0 454.31V57.77c0-15.96 6.49-30.52 16.94-40.89C27.46 6.35 41.87 0 57.83 0zm192.74 235.68c7.85 4.53 12.95 8.28 19.04 14.99 15.79-25.4 32.91-39.48 55.2-59.46l2.17-.84h24.38c-32.68 36.31-58.03 66.23-80.72 110.01-11.81-25.26-22.35-42.7-45.9-58.87l25.82-5.83h.01zm157.25-85.12c3.4 129.29-42.41 198.51-119.74 226.72h-.06c-77.32-28.21-123.13-97.43-119.74-226.72 41.74 2.2 87.59-9.32 119.74-37.08 36.26 27.92 74.21 39.48 119.8 37.08zm-21.96 17.39c2.76 105.58-34.64 162.1-97.79 185.13l-.01-.01-.03.01c-63.14-23.03-100.55-79.55-97.77-185.13 34.07 1.8 71.51-7.6 97.77-30.28 29.6 22.8 60.6 32.24 97.83 30.28zM152.38 449.91c13.08 0 23.77 10.53 23.77 23.77 0 13.1-10.53 23.79-23.77 23.79-13.08 0-23.79-10.54-23.79-23.79 0-13.07 10.53-23.77 23.79-23.77z"/></svg>
                  </div>
                  <div class="title">
                    <b> Aktif</b>
                    <small>SMS Koruma</small>
                  </div>
              </div>
              <div class="tag success">
                  <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 371.13"><path d="M397 141.12c63.51 0 115 51.5 115 115.01 0 63.5-51.49 115-115 115s-115.02-51.5-115.02-115c0-63.51 51.51-115.01 115.02-115.01zM28.8 0h389.26c15.73 0 28.52 12.88 28.5 28.53l-.1 95.75c-7.58-2.84-15.46-5.04-23.59-6.55l.07-77.07-125.82 98.89 9.17 8.99c-3.04 2.56-5.94 5.24-8.75 8.04l-.09.1c-3.27 3.27-6.37 6.72-9.32 10.29l-10.89-10.69-42.14 35.87c-4.49 3.77-11.46 4.22-16.5.11l-44.24-36.09L39.45 282.69h219.27a140.75 140.75 0 0 0 6.69 23.58l-236.92.02C12.74 306.29 0 293.42 0 277.76L.24 28.52C.27 12.84 13.05 0 28.8 0zm-5.19 261.9 130.45-122.08L23.82 41.69l-.21 220.21zM42.65 23.6l183.96 141.87L400.69 23.6H42.65zm312.1 217.84h2.21v-6.8c0-11.34 4.47-21.7 11.68-29.21 7.27-7.57 17.31-12.27 28.36-12.27s21.08 4.69 28.35 12.27c7.21 7.51 11.67 17.87 11.67 29.21v6.8h2.21c3.6 0 6.53 2.93 6.53 6.53v59.46c0 3.6-2.93 6.53-6.53 6.53h-84.48c-3.6 0-6.53-2.93-6.53-6.53v-59.46c0-3.6 2.93-6.53 6.53-6.53zm37.06 39.19-4.23 15.25h18.81l-3.73-15.49c4.01-2.06 6.75-6.22 6.75-11.04 0-6.85-5.57-12.4-12.42-12.4s-12.41 5.55-12.41 12.4c0 5.01 2.96 9.32 7.23 11.28zm-22.49-39.19h55.33v-6.8c0-8.06-3.14-15.37-8.2-20.66-5.01-5.22-11.9-8.45-19.46-8.45-7.56 0-14.46 3.23-19.46 8.45-5.06 5.29-8.21 12.6-8.21 20.66v6.8z"/></svg>
                  </div>
                  <div class="title">
                    <b> Aktif</b>
                    <small>Mail Koruma</small>
                  </div>
              </div>
            </div>
          </div>
          <div class="item organizations" style="display:none">
            <p>Firma</p>
            <div class="organization">
              <img src="https://avatars.githubusercontent.com/u/88612694?s=64&v=4" width="20">
              Eus Teknoloji
            </div>
          </div>
        </div>
      </div>
      <div class="user-c">
        <el-tabs v-model="activePage" tab-position="left" style="height: 200px;">
          <el-tab-pane name="info">
            <span slot="label">
              <svg viewBox="0 0 24 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8Z" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.43094 16.9025C7.05587 16.2213 9.2233 16 12 16C14.771 16 16.9351 16.2204 18.5586 16.8981C20.3012 17.6255 21.3708 18.8613 21.941 20.6587C22.1528 21.3267 21.6518 22 20.9592 22H3.03459C2.34482 22 1.84679 21.3297 2.0569 20.6654C2.62537 18.8681 3.69119 17.6318 5.43094 16.9025Z" /> </svg>
              Hesap Bilgileri</span>

              <profileInfo/>

          </el-tab-pane>
          <el-tab-pane name="settings">
            <span slot="label">
              <svg viewBox="0 -8 72 72"><path d="M56.74,20.89l-1-2.31c3.33-7.53,3.11-7.75,2.46-8.41L54,6l-.42-.35h-.49c-.26,0-1,0-7.51,2.93l-2.38-1C40.09,0,39.77,0,38.87,0h-6c-.9,0-1.25,0-4.1,7.66l-2.37,1C22,6.78,19.45,5.84,18.75,5.84l-.56,0-4.58,4.49c-.7.65-.94.88,2.58,8.3l-1,2.3c-7.79,3-7.79,3.3-7.79,4.23v5.89c0,.92,0,1.25,7.82,4l1,2.29c-3.33,7.53-3.11,7.76-2.46,8.41L18,50l.42.37h.5c.25,0,1,0,7.5-3l2.38,1C31.9,56,32.21,56,33.12,56h6c.92,0,1.25,0,4.11-7.66l2.39-1c4.37,1.85,6.93,2.79,7.61,2.79l.57,0,4.62-4.52c.66-.66.89-.89-2.62-8.28l1-2.3c7.81-3,7.81-3.33,7.81-4.23V24.93C64.57,24,64.57,23.68,56.74,20.89ZM36,37.8A9.8,9.8,0,1,1,46,28,9.91,9.91,0,0,1,36,37.8Z"/></svg>
              Hesap Ayarları</span>

              <profileSettings/>
          </el-tab-pane>
          <el-tab-pane name="security">
            <span slot="label">
              <svg shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 466 512.14"><path d="M232.2 0c86.89 55.08 165.39 81.13 232.75 74.98 11.77 237.97-76.11 378.5-231.86 437.16C82.7 457.24-6.26 322.75.34 71.36 79.42 75.5 157.01 58.41 232.2 0zm46.25 220.22v-16.84c0-13.26-5.16-25.28-13.48-33.95-8.22-8.57-19.54-13.9-31.98-13.9-12.43 0-23.75 5.33-31.97 13.9-8.32 8.67-13.48 20.69-13.48 33.95v16.84h90.91zm-111.23 0v-16.84c0-18.65 7.35-35.64 19.19-47.99 11.95-12.45 28.43-20.18 46.59-20.18 18.16 0 34.65 7.72 46.58 20.18 11.85 12.35 19.2 29.34 19.2 47.99v16.84h7.27c5.9 0 10.73 4.83 10.73 10.73v113.42c0 5.91-4.83 10.74-10.73 10.74h-146.1c-5.91 0-10.73-4.83-10.73-10.74V230.95c-.01-5.9 4.82-10.73 10.73-10.73h7.27zm56.97 72.44-11.59 30.33h40.79l-10.73-30.75c6.81-3.5 11.46-10.6 11.46-18.78 0-11.68-9.45-21.13-21.13-21.13-11.66 0-21.12 9.45-21.12 21.13 0 8.51 5.05 15.85 12.32 19.2zm8.16-253.02c72.06 45.67 141.22 71.35 197.09 66.24 9.76 197.35-67.19 317.97-196.33 366.61C108.37 426.97 30.53 311.37 36 102.89c72.38 3.79 138.71-18.47 196.35-63.25z"/></svg>
              Parola Ve Koruma </span>

              <profileSecurity/>
          </el-tab-pane>
          <el-tab-pane name="files" v-if="false">
            <span slot="label">
              <svg shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 399 511.66"><path fill-rule="nonzero" d="M71.1 0h190.92c5.22 0 9.85 2.5 12.77 6.38L394.7 136.11c2.81 3.05 4.21 6.92 4.21 10.78l.09 293.67c0 19.47-8.02 37.23-20.9 50.14l-.09.08c-12.9 12.87-30.66 20.88-50.11 20.88H71.1c-19.54 0-37.33-8.01-50.22-20.9C8.01 477.89 0 460.1 0 440.56V71.1c0-19.56 8-37.35 20.87-50.23C33.75 8 51.54 0 71.1 0zm45.78 254.04c-8.81 0-15.96-7.15-15.96-15.95 0-8.81 7.15-15.96 15.96-15.96h165.23c8.81 0 15.96 7.15 15.96 15.96 0 8.8-7.15 15.95-15.96 15.95H116.88zm0 79.38c-8.81 0-15.96-7.15-15.96-15.96 0-8.8 7.15-15.95 15.96-15.95h156.47c8.81 0 15.96 7.15 15.96 15.95 0 8.81-7.15 15.96-15.96 15.96H116.88zm0 79.39c-8.81 0-15.96-7.15-15.96-15.96s7.15-15.95 15.96-15.95h132.7c8.81 0 15.95 7.14 15.95 15.95 0 8.81-7.14 15.96-15.95 15.96h-132.7zm154.2-363.67v54.21c1.07 13.59 5.77 24.22 13.99 31.24 8.63 7.37 21.65 11.52 38.95 11.83l36.93-.05-89.87-97.23zm96.01 129.11-43.31-.05c-25.2-.4-45.08-7.2-59.39-19.43-14.91-12.76-23.34-30.81-25.07-53.11l-.15-2.22V31.91H71.1c-10.77 0-20.58 4.42-27.68 11.51-7.09 7.1-11.51 16.91-11.51 27.68v369.46c0 10.76 4.43 20.56 11.52 27.65 7.11 7.12 16.92 11.53 27.67 11.53h256.8c10.78 0 20.58-4.4 27.65-11.48 7.13-7.12 11.54-16.93 11.54-27.7V178.25z"/></svg>
              Dosyalarım </span>
              <profileFiles/>
          </el-tab-pane>
          <el-tab-pane name="organizations">
            <span slot="label">
              <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 406.05"><path d="M389.46 266.26c-14.01 15.36-30.32 29.12-48.63 40.24-2.26 1.66-5.31 1.86-7.82.25-27.05-17.21-49.78-37.88-67.64-60.35-24.65-30.92-40.16-65.19-45.47-98.21-5.41-33.47-.35-65.69 16.31-91.87 6.56-10.34 14.95-19.77 25.17-27.91C284.88 9.7 311.72-.2 338.48 0c25.75.2 51.18 9.8 73.15 29.87 7.72 7.01 14.2 15.05 19.51 23.77 17.92 29.51 21.78 67.14 13.91 105.27-7.77 37.68-27.06 75.96-55.59 107.28v.07zm-190.3-50.94c8.38 0 15.18 6.8 15.18 15.18 0 8.39-6.8 15.18-15.18 15.18H68.63l-33.9 130.01h442.32l-35.22-131.15c-2.16-8.09 2.65-16.4 10.73-18.56 8.09-2.16 16.4 2.64 18.56 10.73l40.12 149.41c.49 1.5.76 3.09.76 4.75 0 8.38-6.8 15.18-15.18 15.18H15.13V406c-1.25 0-2.52-.16-3.79-.48-8.09-2.1-12.95-10.36-10.85-18.45l41.5-159.16c1.23-7.15 7.46-12.59 14.96-12.59h142.21zM333.37 63c32.81 0 59.41 26.6 59.41 59.41 0 32.81-26.6 59.41-59.41 59.41-32.81 0-59.41-26.6-59.41-59.41 0-32.81 26.6-59.41 59.41-59.41z"/></svg>
              Organizasyonları </span>

              <profileOrganizations/>
          </el-tab-pane>
          <el-tab-pane name="accesibility">
            <span slot="label">
              <svg viewBox="0 0 122.88 122.88"><path d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0Zm-.39,74.18L52.1,98.91a4.94,4.94,0,0,1-2.58,2.83A5,5,0,0,1,42.7,95.5l6.24-17.28a26.3,26.3,0,0,0,1.17-4,40.64,40.64,0,0,0,.54-4.18c.24-2.53.41-5.27.54-7.9s.22-5.18.29-7.29c.09-2.63-.62-2.8-2.73-3.3l-.44-.1-18-3.39A5,5,0,0,1,27.08,46a5,5,0,0,1,5.05-7.74l19.34,3.63c.77.07,1.52.16,2.31.25a57.64,57.64,0,0,0,7.18.53A81.13,81.13,0,0,0,69.9,42c.9-.1,1.75-.21,2.6-.29l18.25-3.42A5,5,0,0,1,94.5,39a5,5,0,0,1,1.3,7,5,5,0,0,1-3.21,2.09L75.15,51.37c-.58.13-1.1.22-1.56.29-1.82.31-2.72.47-2.61,3.06.08,1.89.31,4.15.61,6.51.35,2.77.81,5.71,1.29,8.4.31,1.77.6,3.19,1,4.55s.79,2.75,1.39,4.42l6.11,16.9a5,5,0,0,1-6.82,6.24,4.94,4.94,0,0,1-2.58-2.83L63,74.23,62,72.4l-1,1.78Zm.39-53.52a8.83,8.83,0,1,1-6.24,2.59,8.79,8.79,0,0,1,6.24-2.59Zm36.35,4.43a51.42,51.42,0,1,0,15,36.35,51.27,51.27,0,0,0-15-36.35Z"/></svg>
              Erişebilirlikleri </span>
              <profileAccessibility/>
          </el-tab-pane>
          <el-tab-pane name="sessions">
            <span slot="label">
              <svg viewBox="0 0 122.88 119.36"><title>reset-password</title><path d="M102.58,84.44a5.07,5.07,0,0,1,8.77,5.08,59.65,59.65,0,0,1-81.15,22,5.83,5.83,0,0,1-.69-.39,59.66,59.66,0,0,1-21.7-81,5.14,5.14,0,0,1,.46-.78A59.63,59.63,0,0,1,89.5,8a59.22,59.22,0,0,1,21.7,21.55l1-3.89a5.42,5.42,0,1,1,10.49,2.71L119,42.69a5.52,5.52,0,0,1-.48,1.23,5.43,5.43,0,0,1-6,3.28L98,44.52a5.42,5.42,0,0,1,2-10.66l2.33.43a49.56,49.56,0,0,0-85.31.37l-.14.26A49.55,49.55,0,0,0,34.9,102.57h0a49.54,49.54,0,0,0,67.66-18.14Zm-22-14.06h0l5.75,5.75h0l3.52,3.52L84.15,85.4l-3.52-3.52-5.57,5.57L69.31,81.7l5.57-5.57-3-3-6.41,6.42-5.75-5.75,6.42-6.42-2-2-2-2,0,0a16.95,16.95,0,0,1-23.92,0h0l-.28-.3a16.92,16.92,0,0,1,.28-23.63h0L44,33.64a16.93,16.93,0,0,1,24,23.93h0l0,0L80.63,70.38ZM61.31,40.23a7.67,7.67,0,0,0-10.77,0L44.73,46h0a7.68,7.68,0,0,0-.19,10.58l.2.19h0a7.68,7.68,0,0,0,10.77,0L61.31,51h0a7.68,7.68,0,0,0,0-10.77Z"/></svg>
              Oturumlar </span>
              <profileSessions/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
   </div>
</template>
  
  <script>
    import profileInfo from './info'
    import profileSettings from './settings'
    import profileSecurity from './security'
    import profileFiles from './files'
    import profileOrganizations from './organizations'
    import profileAccessibility from './accessibility'
    import profileSessions from './sessions'

    export default{
      data(){
        return{
          activePage: this.$route.params.section
        }
      },
      components:{
        profileInfo,
        profileSettings,
        profileSecurity,
        profileFiles,
        profileOrganizations,
        profileAccessibility,
        profileSessions,
      },
      watch:{
        '$route.params.section'(val){
          this.activePage = val;
        },
        activePage(){
          this.$router.push({params:{ section : this.activePage}})
        }
      },
      mounted(){
          document.querySelector("body").style.setProperty('--accountPageHeight', window.innerHeight + 'px');
      },
    }
  </script>