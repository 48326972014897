<template>
    <el-menu router :mode="isMobile ? 'vertical' : 'horizontal'"  :default-active="$route.path" v-if="user">
        <template v-for="(page, index) in $router.options.routes">
              <slot v-if="!page.property.is_admin || user.is_admin">
                <el-submenu v-if="page.children && page.property.visible && page.children.length > 0" :key="index" :index="page.path">
                    <template slot="title"><span v-html="page.property.icon"></span> {{ page.property.title }}</template>
                      <slot  v-for="(child, index) in page.children">
                        <slot v-if="!child.property.is_admin || user.is_admin">
                          <slot v-if="!child.property.is_reseller || user.is_reseller">
                              <el-menu-item v-if="child.property.visible"  :key="index" :index="page.path + '/' + child.path">
                                {{ child.property.title }}
                              </el-menu-item>
                          </slot>
                        </slot>
                      </slot>
                </el-submenu>
                <el-menu-item v-if="_.isUndefined(page.children) && page.property.visible" :key="index" :index="page.path">
                  <span v-html="page.property.icon"></span>  {{ page.property.title }}
                </el-menu-item>
              </slot>
        </template>
        <!-- 
        <el-menu-item exact @click="$router.push({name: 'Profile', params:{ section: 'info'}})">
          <i class="el-icon-user"></i>
          Depo Yönetimi 
        </el-menu-item>
        <el-menu-item exact @click="$router.push({name: 'Profile', params:{ section: 'info'}})">
          <i class="el-icon-user"></i>
          Kullanıcı Yönetimi 
        </el-menu-item>
        <el-menu-item exact @click="$router.push({name: 'Profile', params:{ section: 'info'}})">
          <i class="el-icon-user"></i>
          Sistem Yönetimi 
        </el-menu-item>
        <el-menu-item exact @click="$router.push({name: 'Profile', params:{ section: 'info'}})">
          <i class="el-icon-user"></i>
          Organizasyon Yönetimi 
        </el-menu-item>
       
          -----------

          -----------

          Depo Yönetiminde Depo Listesi, depodaki evraklar, Organizasyonlar kullanıcılar gibi linklemeler olacak, sadece resmi ve adı değişebilecek. 
          Bu değişimlerin logları altında görünecek detay sayfasında.

          -----------

          Kullanıcı yönetiminde standart kullanıcı yönetimi


          -----------


          Organizasyon Yönetimi 

          Organizasyon Listesi,

          Organizasyon Oluştur,

          Organizasyon Ayarları (Herkes katılabilir mi?, Davet Linki, Bağlı Olan Kullanıcılar, Dosyaları vs.. vs..)

          ------------

          Süreç Yönetimine Resim Eklenecek

          -----------
          

          Sistem Yönetimine

          Organizasyonlar Sayfasının İçeriğini Koyacaksın


          Sunucu Yönetimi,
          Yedekleme Yönetimi
          Entegrasyon Yönetimi
          
          Hakkında

        -->
    </el-menu>
</template>
<script>

  export default{
    methods:{
      logout(){
          this.$store.dispatch('auth/logout');
      },
    },
    computed:{
      user(){
        return this.$user();
      }
    },
  }
  
</script>