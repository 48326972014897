<template>
   <div class="app-user-t">
    <div class="user-t">
      <div class="user-h">
        <div class="l-r">
          <div class="profile">
              <el-avatar src="https://avatars.githubusercontent.com/u/88612694?s=64&v=4"></el-avatar>
              <div class="title">
                <p>Eus Teknoloji</p>
                <span>LTD. ŞTİ.</span>
              </div>
          </div>
          <div class="item" style="margin-left:20px">
            <div><i class="el-icon-user"></i> Organizasyon Hesabı</div>
            <div>
              <i class="el-icon-location"></i>
              İstanbul, Türkiye
            </div>
          </div>
          <div class="item" style="margin-left:20px">
            <div><i class="el-icon-phone"></i> +90 502 002 22 11</div>
            <div>
              <i class="el-icon-message"></i>
              info@euscreative.com
            </div>
          </div>
        </div>
      </div>
      <div class="user-c">
        <el-tabs v-model="activePage" tab-position="left" style="height: 200px;">
          <el-tab-pane name="info">
            <span slot="label">
              <svg viewBox="0 0 24 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8Z" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.43094 16.9025C7.05587 16.2213 9.2233 16 12 16C14.771 16 16.9351 16.2204 18.5586 16.8981C20.3012 17.6255 21.3708 18.8613 21.941 20.6587C22.1528 21.3267 21.6518 22 20.9592 22H3.03459C2.34482 22 1.84679 21.3297 2.0569 20.6654C2.62537 18.8681 3.69119 17.6318 5.43094 16.9025Z" /> </svg>
              Organizasyon Bilgileri</span>

              <profileInfo/>

          </el-tab-pane>
          <el-tab-pane name="settings">
            <span slot="label">
              <svg viewBox="0 -8 72 72"><path d="M56.74,20.89l-1-2.31c3.33-7.53,3.11-7.75,2.46-8.41L54,6l-.42-.35h-.49c-.26,0-1,0-7.51,2.93l-2.38-1C40.09,0,39.77,0,38.87,0h-6c-.9,0-1.25,0-4.1,7.66l-2.37,1C22,6.78,19.45,5.84,18.75,5.84l-.56,0-4.58,4.49c-.7.65-.94.88,2.58,8.3l-1,2.3c-7.79,3-7.79,3.3-7.79,4.23v5.89c0,.92,0,1.25,7.82,4l1,2.29c-3.33,7.53-3.11,7.76-2.46,8.41L18,50l.42.37h.5c.25,0,1,0,7.5-3l2.38,1C31.9,56,32.21,56,33.12,56h6c.92,0,1.25,0,4.11-7.66l2.39-1c4.37,1.85,6.93,2.79,7.61,2.79l.57,0,4.62-4.52c.66-.66.89-.89-2.62-8.28l1-2.3c7.81-3,7.81-3.33,7.81-4.23V24.93C64.57,24,64.57,23.68,56.74,20.89ZM36,37.8A9.8,9.8,0,1,1,46,28,9.91,9.91,0,0,1,36,37.8Z"/></svg>
              Organizasyon Ayarları</span>

              <profileSettings/>
          </el-tab-pane>
          <el-tab-pane name="files" v-if="false">
            <span slot="label">
              <svg shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 399 511.66"><path fill-rule="nonzero" d="M71.1 0h190.92c5.22 0 9.85 2.5 12.77 6.38L394.7 136.11c2.81 3.05 4.21 6.92 4.21 10.78l.09 293.67c0 19.47-8.02 37.23-20.9 50.14l-.09.08c-12.9 12.87-30.66 20.88-50.11 20.88H71.1c-19.54 0-37.33-8.01-50.22-20.9C8.01 477.89 0 460.1 0 440.56V71.1c0-19.56 8-37.35 20.87-50.23C33.75 8 51.54 0 71.1 0zm45.78 254.04c-8.81 0-15.96-7.15-15.96-15.95 0-8.81 7.15-15.96 15.96-15.96h165.23c8.81 0 15.96 7.15 15.96 15.96 0 8.8-7.15 15.95-15.96 15.95H116.88zm0 79.38c-8.81 0-15.96-7.15-15.96-15.96 0-8.8 7.15-15.95 15.96-15.95h156.47c8.81 0 15.96 7.15 15.96 15.95 0 8.81-7.15 15.96-15.96 15.96H116.88zm0 79.39c-8.81 0-15.96-7.15-15.96-15.96s7.15-15.95 15.96-15.95h132.7c8.81 0 15.95 7.14 15.95 15.95 0 8.81-7.14 15.96-15.95 15.96h-132.7zm154.2-363.67v54.21c1.07 13.59 5.77 24.22 13.99 31.24 8.63 7.37 21.65 11.52 38.95 11.83l36.93-.05-89.87-97.23zm96.01 129.11-43.31-.05c-25.2-.4-45.08-7.2-59.39-19.43-14.91-12.76-23.34-30.81-25.07-53.11l-.15-2.22V31.91H71.1c-10.77 0-20.58 4.42-27.68 11.51-7.09 7.1-11.51 16.91-11.51 27.68v369.46c0 10.76 4.43 20.56 11.52 27.65 7.11 7.12 16.92 11.53 27.67 11.53h256.8c10.78 0 20.58-4.4 27.65-11.48 7.13-7.12 11.54-16.93 11.54-27.7V178.25z"/></svg>
              Dosyalarım </span>
              <profileFiles/>
          </el-tab-pane>
          <el-tab-pane name="users">
            <span slot="label">
              <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 406.05"><path d="M389.46 266.26c-14.01 15.36-30.32 29.12-48.63 40.24-2.26 1.66-5.31 1.86-7.82.25-27.05-17.21-49.78-37.88-67.64-60.35-24.65-30.92-40.16-65.19-45.47-98.21-5.41-33.47-.35-65.69 16.31-91.87 6.56-10.34 14.95-19.77 25.17-27.91C284.88 9.7 311.72-.2 338.48 0c25.75.2 51.18 9.8 73.15 29.87 7.72 7.01 14.2 15.05 19.51 23.77 17.92 29.51 21.78 67.14 13.91 105.27-7.77 37.68-27.06 75.96-55.59 107.28v.07zm-190.3-50.94c8.38 0 15.18 6.8 15.18 15.18 0 8.39-6.8 15.18-15.18 15.18H68.63l-33.9 130.01h442.32l-35.22-131.15c-2.16-8.09 2.65-16.4 10.73-18.56 8.09-2.16 16.4 2.64 18.56 10.73l40.12 149.41c.49 1.5.76 3.09.76 4.75 0 8.38-6.8 15.18-15.18 15.18H15.13V406c-1.25 0-2.52-.16-3.79-.48-8.09-2.1-12.95-10.36-10.85-18.45l41.5-159.16c1.23-7.15 7.46-12.59 14.96-12.59h142.21zM333.37 63c32.81 0 59.41 26.6 59.41 59.41 0 32.81-26.6 59.41-59.41 59.41-32.81 0-59.41-26.6-59.41-59.41 0-32.81 26.6-59.41 59.41-59.41z"/></svg>
              Kullanıcılar </span>

              <profileUsers/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
   </div>
</template>
  
  <script>
    import profileInfo from './info'
    import profileSettings from './settings'
    import profileSecurity from './security'
    import profileFiles from './files'
    import profileUsers from './users'
    import profileOrganizations from './organizations'
    import profileAccessibility from './accessibility'
    import profileSessions from './sessions'

    export default{
      data(){
        return{
          activePage: this.$route.params.section
        }
      },
      components:{
        profileInfo,
        profileSettings,
        profileSecurity,
        profileFiles,
        profileOrganizations,
        profileAccessibility,
        profileUsers,
        profileSessions,
      },
      watch:{
        '$route.params.section'(val){
          this.activePage = val;
        },
        activePage(){
          this.$router.push({params:{ section : this.activePage}})
        }
      },
      mounted(){
          document.querySelector("body").style.setProperty('--accountPageHeight', window.innerHeight + 'px');
      },
    }
  </script>