<template>
  <div>
    <h2>Şifresini Değiştir</h2>
    <hr>
    <el-form size="small" label-position="top" v-if="!_.isEmpty(form)">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Yeni Şifre">
            <el-input size="medium" autocomplete="off" type="password" required placeholder="Şifre" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="Yeni Şifre Tekrar">
            <el-input size="medium" autocomplete="off" type="password" required placeholder="Şifre" v-model="form.password"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <h4>En az 15 karakter VEYA bir sayı ve bir küçük harf dahil en az 8 karakter olduğundan emin olun.</h4>
    <el-button type="primary"> <i class="el-icon-check"></i> Şifre Güncelle</el-button>

    <br><br>
    <h2>İki faktörlü kimlik doğrulama</h2>
    <hr>
    <h4>İki faktörlü kimlik doğrulama, oturum açmak için paroladan fazlasını gerektirerek hesabınıza ek bir güvenlik katmanı ekler. </h4>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>İki Faktörlü Doğrulama</span>
      </div>
      <div class="info-item">
        <div class="left">
          <div class="icon">
            <svg shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 444 512.36"><path d="M75.33 320.47h294.33c29.56-56.91 43.93-129.92 39.5-219.58-53.23 4.86-119.13-19.6-187.78-63.12-54.92 42.67-118.12 63.87-187.08 60.26-2.43 92.68 12.43 166.09 41.03 222.44zm328.53.58c14.79 2.87 26.06 16 26.06 31.53v127.66c0 17.59-14.52 32.12-32.12 32.12H46.18c-17.59 0-32.11-14.46-32.11-32.12V352.58c0-15.96 11.87-29.43 27.32-31.75C12.17 255.78-2.41 172.49.33 67.99 75.67 71.94 149.6 55.65 221.23 0 304.03 52.48 378.81 77.3 443 71.44c5.01 101.3-8.95 184.06-39.14 249.61zM161.67 177.27h6.01v-13.91c0-15.4 6.07-29.44 15.84-39.64 9.87-10.28 23.48-16.66 38.48-16.66 14.99 0 28.62 6.38 38.47 16.66 9.78 10.2 15.85 24.23 15.85 39.64v13.91h6c4.88 0 8.87 3.99 8.87 8.86v93.66c0 4.88-3.99 8.87-8.87 8.87H161.67c-4.88 0-8.86-3.99-8.86-8.87v-93.66c-.01-4.87 3.98-8.86 8.86-8.86zm22.78 0h75.09v-13.91c0-10.96-4.27-20.88-11.14-28.04-6.79-7.09-16.14-11.48-26.41-11.48-10.26 0-19.62 4.39-26.4 11.48-6.88 7.16-11.14 17.08-11.14 28.04v13.91zm30.27 59.82-9.56 25.05h33.68l-8.86-25.4c5.62-2.89 9.47-8.75 9.47-15.51 0-9.64-7.81-17.45-17.46-17.45-9.63 0-17.44 7.81-17.44 17.45 0 7.03 4.17 13.1 10.17 15.86zM105.2 397.93l-3.62-20.7c11.68-3.52 23.3-5.28 34.89-5.28 4.63 0 8.49.13 11.6.41 3.1.28 6.32.97 9.66 2.08 3.33 1.12 5.97 2.64 7.91 4.59 4.46 4.44 6.68 11.26 6.68 20.43s-2.6 15.93-7.79 20.29c-5.18 4.35-16.12 9.35-32.79 15v3.9h39.18v22.23H99.64v-17.37c0-5.19.97-10.14 2.91-14.87 1.21-2.69 4.13-5.93 8.76-9.73 2.5-2.13 5.86-4.17 10.07-6.11 4.22-1.95 8.2-3.78 11.96-5.49 3.75-1.71 6.78-3.13 9.1-4.24v-7.5c-4.17-.46-8.02-.7-11.54-.7-8.52 0-17.09 1.02-25.7 3.06zm140.7 33.06h-23.62v29.89h-27.8v-86.86h56.98l-3.48 22.25h-25.7v14.01h23.62v20.71zm38.64 29.89h-28.15l22.52-86.86h42.94l22.51 86.86h-28.15l-3.2-13.75h-25.27l-3.2 13.75zm12.13-60.17-3.93 24.32h15.13l-3.8-24.32h-7.4z"/></svg>
          </div>
          <div class="text">
            <h3>Doğrulayıcı uygulaması <el-tag style="margin-left:10px" type="success" size="small" effect="dark">Aktif</el-tag></h3>
            <small>İstendiğinde iki faktörlü kimlik doğrulama kodları almak için telefonunuzdaki bir uygulamayı kullanın.</small>
          </div>
        </div>
        <div class="button">
          <el-button type="default" plain>  <i class="el-icon-remove"></i> Sıfırla</el-button>
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <div class="icon">
            <svg viewBox="0 0 84.33 122.88"><path class="cls-1" d="M75.59,28.93c3.65-.37,6.1,1.18,6.54,3.57.65,3.52-2.77,6.1-5.23,8-.43.33-.87.65-1.31.95V28.93Zm-58-16.51h0c5.84-.28,7.37,5.84,6.86,10.71-.3,2.87-1.06,3.92-2.32,6.44-1.48,2.39-3,4.72-4.4,7.17V90.21a12.1,12.1,0,0,0,.93,4.65,12.28,12.28,0,0,0,2.63,3.93A12.06,12.06,0,0,0,25,101.36l.15.07a12.1,12.1,0,0,0,4.65.93H61.22C55.09,108.11,48.48,112,41,112.22l-7.77,9.44c-1.68,1.54-3.63,1.71-6,0L4.7,106.58C2.36,105,.05,103.43,0,100.23,0,98,1.34,95.72,3.36,93.48a14.08,14.08,0,0,0,2-7.18C5.53,81.13,3,76.61,2.63,71c-.43-6.3,0-14.79,1-24.54l.46-13.17A7.45,7.45,0,0,1,5.26,30L16.84,12.51l.71-.09Zm57.31,42.7V51.65a12.13,12.13,0,0,1,8,3.87c3,3.61,1,8.77-2.24,11.25a4.91,4.91,0,0,1-2.31.91l.31.06c3.15,2.65,4.69,5.62,4.09,8.5-.87,4.24-7.66,8.62-11.74,8.92a4.65,4.65,0,0,1-4.51-2.22c-2.32-3.6,1.19-7.45,3.61-9.84a15.89,15.89,0,0,1,4.72-3.28V67.17a5,5,0,0,1-3.07-3.35c-.7-3.18,1.34-6.26,3.14-8.7Zm-.35,31.15.27-1.4c3.29-.26,5.71.86,6.94,3.85.53,3,.22,6-2.55,7.7C76.6,98,71,99,68.3,97.22c-2.88-1.9-2.51-5.17-.33-7.49a12.06,12.06,0,0,1,6.54-3.46ZM29.83,0H63.45A9.54,9.54,0,0,1,73,9.54V51.31a1.87,1.87,0,0,0,0,.34v2.86a21.88,21.88,0,0,0-2.34,3.91,11.27,11.27,0,0,0-.78,2.45V10.4H23.44v2a6.85,6.85,0,0,0-3.14-1.8v-1A9.51,9.51,0,0,1,29.83,0Zm16.8,89.49a3.93,3.93,0,1,1-3.92,3.92,3.91,3.91,0,0,1,3.92-3.92Zm23.2-25.38,0,.12a6,6,0,0,0,1.49,2.82,7.19,7.19,0,0,0,1.53,1.26v.33a18,18,0,0,0-1.64,1c-.49.33-.95.7-1.41,1.08V64.11ZM67.71,86.5h0a6.09,6.09,0,0,0,.75.28,12.08,12.08,0,0,0-1.89,1.64,8.66,8.66,0,0,0-1,1.22,7.2,7.2,0,0,0-1.27,4.19A5.91,5.91,0,0,0,66.26,98a7.1,7.1,0,0,0,1,.78l.14.09a9.45,9.45,0,0,1-3.94.85H29.83a9.53,9.53,0,0,1-9.53-9.54V36.44l.14-.26,3-4.85V86.5H67.71Z"/></svg>
          </div>
          <div class="text">
            <h3>SMS/Metin mesajı <el-tag style="margin-left:10px" type="success" size="small" effect="dark">Aktif</el-tag></h3>
            <small>Kimlik doğrulama isteklerini tamamlamak için SMS yoluyla telefonunuza gönderilen tek seferlik kodları alın.

            </small>
          </div>
        </div>
        <div class="button">
          <el-button type="default" plain>  <i class="el-icon-remove"></i> Sıfırla</el-button>
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 371.13"><path d="M397 141.12c63.51 0 115 51.5 115 115.01 0 63.5-51.49 115-115 115s-115.02-51.5-115.02-115c0-63.51 51.51-115.01 115.02-115.01zM28.8 0h389.26c15.73 0 28.52 12.88 28.5 28.53l-.1 95.75c-7.58-2.84-15.46-5.04-23.59-6.55l.07-77.07-125.82 98.89 9.17 8.99c-3.04 2.56-5.94 5.24-8.75 8.04l-.09.1c-3.27 3.27-6.37 6.72-9.32 10.29l-10.89-10.69-42.14 35.87c-4.49 3.77-11.46 4.22-16.5.11l-44.24-36.09L39.45 282.69h219.27a140.75 140.75 0 0 0 6.69 23.58l-236.92.02C12.74 306.29 0 293.42 0 277.76L.24 28.52C.27 12.84 13.05 0 28.8 0zm-5.19 261.9 130.45-122.08L23.82 41.69l-.21 220.21zM42.65 23.6l183.96 141.87L400.69 23.6H42.65zm312.1 217.84h2.21v-6.8c0-11.34 4.47-21.7 11.68-29.21 7.27-7.57 17.31-12.27 28.36-12.27s21.08 4.69 28.35 12.27c7.21 7.51 11.67 17.87 11.67 29.21v6.8h2.21c3.6 0 6.53 2.93 6.53 6.53v59.46c0 3.6-2.93 6.53-6.53 6.53h-84.48c-3.6 0-6.53-2.93-6.53-6.53v-59.46c0-3.6 2.93-6.53 6.53-6.53zm37.06 39.19-4.23 15.25h18.81l-3.73-15.49c4.01-2.06 6.75-6.22 6.75-11.04 0-6.85-5.57-12.4-12.42-12.4s-12.41 5.55-12.41 12.4c0 5.01 2.96 9.32 7.23 11.28zm-22.49-39.19h55.33v-6.8c0-8.06-3.14-15.37-8.2-20.66-5.01-5.22-11.9-8.45-19.46-8.45-7.56 0-14.46 3.23-19.46 8.45-5.06 5.29-8.21 12.6-8.21 20.66v6.8z"></path></svg>
          </div>
          <div class="text">
            <h3>Mail Doğrulama <el-tag style="margin-left:10px" type="success" size="small" effect="dark">Aktif</el-tag></h3>
            <small>Kimlik doğrulama isteklerini tamamlamak için mailinize gelen tek seferlik kodları alın.

            </small>
          </div>
        </div>
        <div class="button">
          <el-button type="default" plain>  <i class="el-icon-remove"></i> Sıfırla</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default{
    name:'Edit',
    data(){
      return{
          form:{
            old_password:null,
            new_password:null,
            new_again_password:null,
          },
          loading: false,
      }
    },
    created(){
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods:{
      edit(){
        this.loading = true;
        axios.patch('/admin/users/update', this.form)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('auth/userInit');
              this.$store.dispatch('alert/message', { type: 'success', title: 'Güncelleme Başarılı !'});
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      }
    }
  }
</script>