<template>
  <div>
    <h2>Kullanıcı Adı Değiştir</h2>
    <hr>
    <h4>Kullanıcı adı değiştirmek bazı <el-link type="primary">yan etkilere</el-link> sebep olabilir.</h4>
    <el-button type="info"> <i class="el-icon-user"></i> Kullanıcı Adı Değiştir</el-button>
    <h4><i class="el-icon-warning-outline"></i> Hesabınızın güvenliğini mi artırmak istiyorsunuz ? Parola ve koruma sayfasını inceleyin.</h4>
    <br>
    <h2>Hesap Verilerimi Dışa Aktar</h2>
    <hr>
    <h4>Sistemdeki hareketlerinizi log olarak indirmenizi sağlar.</h4>
    <el-button type="info"> <i class="el-icon-s-data"></i> Dışa Aktar</el-button>

    <br><br>
    <h2>Hesap Silme</h2>
    <hr>
    <h4>Hesabınız şuanda şu organizasyonaa bağlı: <el-link type="primary">euscreative</el-link>.</h4>
    <el-button type="danger" plain> <i class="el-icon-video-pause"></i> Hesabını Sil</el-button>
  </div>
</template>

<script>
  export default{
    name:'Edit',
    data(){
      return{
          id: this.$route.params.id,
          form:{
            name: null,
            surname: null,
            password: null,
            username : null,
            company_name : null,
            sell_limit : null,
            tax : null,
            tax_number : null,
            address : null,
            phone : null,
            email : null,
          },
          loading: false,
      }
    },
    created(){
      this.form.name = this.user.name;
      this.form.surname = this.user.surname;
      this.form.username = this.user.username;
      this.form.company_name = this.user.company_name;
      this.form.sell_limit = this.user.sell_limit;
      this.form.tax = this.user.tax;
      this.form.tax_number = this.user.tax_number;
      this.form.address = this.user.address;
      this.form.phone = this.user.phone;
      this.form.email = this.user.email;
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods:{
      edit(){
        this.loading = true;
        axios.patch('/admin/users/update', this.form)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('auth/userInit');
              this.$store.dispatch('alert/message', { type: 'success', title: 'Güncelleme Başarılı !'});
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      }
    }
  }
</script>