<template>
  <div>
    <h2>Bildirimler</h2>
    <hr>
    <div class="info-item white">
      <div class="left">
        <div class="text">
          <h3>Varsayılan bildirim e-postası</h3>
           <small>E-postaların nereye gönderilmesini istediğinizi seçin. Daha fazla e-posta adresi ekleyebilirsiniz. Bireysel kuruluşlar için kullanılacak farklı e-posta adreslerini belirtmek için özel yolları kullanın.</small>
           <div class="flex" style="margin-top:5px">
            <el-select size="small" v-model="form.mail" placeholder="Select">
              <el-option
                :key="1"
                label="john@euscreative.com"
                :value="1">
              </el-option>
            </el-select>
            <el-button style="margin-left:10px" type="default" size="small">Yeni Mail Ekle</el-button>
           </div>
        </div>
      </div>
    </div>
    <el-row :gutter="10">
      <el-col :span="12">
        <div class="info-item white">
          <div class="left">
            <div class="text">
              <h3>Döküman hareketlerini otomatik olarak izle</h3>
              <small>Bir depoya anında iletme erişimi verildiğinde, bunun için otomatik olarak bildirimler alırsınız.</small>
              <br>
              <el-switch style="margin-top:5px" v-model="notify.watch_1"></el-switch>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="info-item white">
          <div class="left">
            <div class="text">
              <h3>Takım hareketlerini otomatik olarak izle</h3>
              <small>Güncellemelere otomatik olarak abone olursunuz ve ekipten bildirim alırsınız.</small>
              <br>
              <el-switch style="margin-top:5px" v-model="notify.watch_1"></el-switch>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Abonelikler</span>
      </div>
      <div class="info-item white">
        <div class="left">
          <div class="text">
            <h3>Dosya Bilgileri</h3>
            <small>Dosya bilgileri değiştiğinde bildirim alırsınız</small>
            <br>
          </div>
        </div>
        <div class="icon">
          <el-switch style="margin-top:5px" v-model="notify.watch_4"></el-switch>
        </div>
      </div>
      <div class="info-item white">
        <div class="left">
          <div class="text">
            <h3>Destek Talepleri</h3>
            <small>Destek talebinden gelen mesajları billdirim alırsınız</small>
            <br>
          </div>
        </div>
        <div class="icon">
          <el-switch style="margin-top:5px" v-model="notify.watch_3"></el-switch>
        </div>
      </div>
      <div class="info-item white">
        <div class="left">
          <div class="text">
            <h3>Özel Etiketler</h3>
            <small>Özel etiketlerde bir değişiklik olursa billdirim alırsınız</small>
            <br>
          </div>
        </div>
        <div class="icon">
          <el-switch style="margin-top:5px" v-model="notify.watch_5"></el-switch>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default{
    name:'Edit',
    data(){
      return{
          form:{
            mail:1,
          },
          notify:{
            watch_1:true,
            watch_2:true,
            watch_3:false,
          },
          loading: false,
      }
    },
    created(){
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods:{
      edit(){
        this.loading = true;
        axios.patch('/admin/users/update', this.form)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('auth/userInit');
              this.$store.dispatch('alert/message', { type: 'success', title: 'Güncelleme Başarılı !'});
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      }
    }
  }
</script>