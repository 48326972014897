<template>
    <el-row :gutter="10">
        <el-tabs tab-position="left" class="tabs settings-tab">
          <el-alert
            title="Yapım aşamasında"
            type="info"
            description="Yakında bu sayfa hazırlanacaktır."
            show-icon>
          </el-alert>
        </el-tabs>
    </el-row>
</template>
<script>
  export default {
    data() {
      return {
      };
    },
  components: {
  },
  created () {
  },
  methods: {
  }
  }
</script>
