<template>
    <div>
      <el-button type="primary" @click="specialField = true" style="margin-bottom:10px"><i class="el-icon-full-screen"></i> Yeni Barkod Üret</el-button>
      <el-card class="table-card">
        <h3 class="card-title">
         <div>
            <i class="el-icon-full-screen"></i>   Barkodlar
         </div>
        </h3>
        <el-table
        :data="tableData"
        align="left"
        style="width: 100%">
        <el-table-column
          
          label="ID" width="100">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Barkod Kodu">
          <template slot-scope="scope">
            <i class="el-icon-document"></i> {{ scope.row.code }}
          </template>
        </el-table-column>
        <el-table-column
          label="Organizasyon">
          <template slot-scope="scope">
           {{ scope.row.organization }}
          </template>
        </el-table-column>
        <el-table-column
          label="Tür">
          <template slot-scope="scope">
            <i class="el-icon-position"></i> {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column
          label="Tarih">
          <template slot-scope="scope">
           <i class="el-icon-date"></i> {{ scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="Durumu">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="İşlem"
          width="140"
          >
          <template slot-scope="scope">
            <el-button style="width:100%" size="medium" type="danger" plain><i class="el-icon-delete"></i> Barkodu Sil</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    
    <el-dialog width="400px" title="Barkod Ekle" :visible.sync="specialField">
      <div>
        <el-form label-position="top">
          <el-row :gutter="10">
            <el-col :span="12">
                <el-form-item label="Metin Başlangıç">
                  <el-input placeholder="Metin Başlangıç"  v-model="form.start_text"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Metni Sonu">
                  <el-input placeholder="Metni Sonu" v-model="form.end_text"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Başlangıç">
                  <el-input placeholder="Başlangıç" type="number" v-model="form.start"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Son">
                  <el-input placeholder="Son" type="number" v-model="form.end"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="24">
              <el-form-item label="Artış Miktarı">
                <el-input type="number"  v-model="form.multiplication"></el-input>
              </el-form-item>
          </el-col>
          <el-form-item label="Barkod Türü">
                <el-select style="width:100%;" size="large" v-model="barcodeType">
                <el-option value="" label="Code128"></el-option>
                <el-option v-for="(item,key) in barcodeTypes"  :key="key" :value="item" :label="item"></el-option>
                </el-select>
            </el-form-item>
          <el-form-item label="Organizasyon">
              <el-select value="eus" placeholder="Select">
                <el-option
                  key="Eus Teknoloji"
                  label="Eus Teknoloji"
                  value="eus">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="Aktif/Pasif">
            <el-switch v-model="form.status"> </el-switch>
          </el-form-item>
          <el-button style="width:100%" type="primary"><i class="el-icon-full-screen"></i> Barkod Oluştur</el-button>
          <br><br>
        </el-form>
      </div>
    </el-dialog>
    </div>
  </template>
  
  <script>
  import VueBarcode from 'vue-barcode'
    export default{
        data(){
          return{
            specialField:false,
            form:{
              name:"Fatura Numarası",
              barcode: 'text',
              status:false,
              start_text: '',
              end_text: '',
              multiplication: "1",
              start: null,
              end: null
            },
            barcodeType:'CODE128',
            barcodeTypes:['EAN13', 'EAN8', 'EAN5', 'EAN2', 'UPC', 'CODE39', 'ITF14', 'MSI', 'PHARMACODE'],
            tableData:[
              {
                id:1,
                status:1,
                code:"9874654123",
                type:'Code 128',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
              },
              {
                id:2,
                status:1,
                code:"5469878132",
                type:'Code 128',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
              },
              {
                id:3,
                status:1,
                code:"1597534655",
                type:'Code 128',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
              },
              {
                id:4,
                status:1,
                code:"9547531598",
                type:'Code 128',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
              },
            ]
          }
        },
        components: {
        VueBarcode
        },
    }
  </script>