<template>
    <div>
      <el-button type="primary" @click="specialField = true" style="margin-bottom:10px"><i class="el-icon-plus"></i> İş Emri Başlat</el-button>
      <el-card class="table-card">
        <h3 class="card-title">
          <div>
            <i class="el-icon-collection-tag"></i>   İş Emirleri
          </div>
        </h3>
        <el-row :gutter="5">
                <el-col :span="3">
                    <el-input size="medium" placeholder="ID Arama" v-model="form.name"></el-input>
                </el-col>
                <el-col :span="3">
                    <el-input size="medium" placeholder="İsim Arama" v-model="form.name"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-button size="medium" type="primary" plain><i class="el-icon-search"></i> Ara</el-button>
                    <el-button size="medium" type="danger" plain><i class="el-icon-delete"></i> Temizle</el-button>
                </el-col>
        </el-row>
        <hr>
        <el-table
        :data="tableData"
        align="left"
        style="width: 100%">
        <el-table-column
          label="ID" width="100">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Saha" width="100">
          <template slot-scope="scope">
            <i class="el-icon-place"></i>  {{ scope.row.area }}
          </template>
        </el-table-column>
        <el-table-column
          label="Açıklama"  width="300">
          <template slot-scope="scope">
            <i class="el-icon-arrow-right"></i>  {{ scope.row.desc }}
          </template>
        </el-table-column>
        <el-table-column
          label="Tür">
          <template slot-scope="scope">
          <i class="el-icon-arrow-right"></i>    {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column
          label="Öncelik">
          <template slot-scope="scope">
            <i class="el-icon-arrow-right"></i>    {{ scope.row.priority }}
          </template>
        </el-table-column>
        <el-table-column
          label="Organizasyon">
          <template slot-scope="scope">
            <img src="https://avatars.githubusercontent.com/u/88612694?s=64&v=4" width="10">   {{ scope.row.organization }}
          </template>
        </el-table-column>
        <el-table-column
          label="Emri Veren">
          <template slot-scope="scope">
            <div class="flex">
              <img style="border-radius:100%; margin-right:5px" src="https://i.pravatar.cc/321" width="20">   {{ scope.row.from }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Atanan Kişi">
          <template slot-scope="scope">
            <div class="flex">
              <img style="border-radius:100%; margin-right:5px" src="https://i.pravatar.cc/322" width="20">   {{ scope.row.to }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Tarih">
          <template slot-scope="scope">
           <i class="el-icon-date"></i> {{ scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="Durumu">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="İşlem"
          width="250"
          >
          <template slot-scope="scope">
            <el-button size="medium" type="primary" plain><i class="el-icon-check"></i> Tamamla</el-button>
            <el-button size="medium" type="danger" plain><i class="el-icon-remove"></i> İptal Et</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage4"
          :page-sizes="[10,20, 50, 75, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="20">
        </el-pagination>
      </div>
    </el-card>
    
    <el-dialog width="400px" title="İş Emri Ver" :visible.sync="specialField">
      <div>
        <el-form label-position="top">
          <el-form-item label="Kullanıcı Arayın">
            <el-input placeholder="Kullanıcı Arayın" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Açıklama">
            <el-input placeholder="Açıklama yazın" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="Organizasyon">
              <el-select placeholder="Seçiniz">
                <el-option
                  key="Eus Teknoloji"
                  label="Eus Teknoloji"
                  value="Eus Teknoloji">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="Tür">
              <el-select placeholder="Seçiniz">
                <el-option
                  key="İmzalama"
                  label="İmzalama"
                  value="İmzalama">
                </el-option>
                <el-option
                  key="İmha"
                  label="İmha"
                  value="İmha">
                </el-option>
                <el-option
                  key="Taşıma"
                  label="Taşıma"
                  value="Taşıma">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="Alan">
              <el-select placeholder="Seçiniz">
                <el-option
                  key="Depo"
                  label="Depo"
                  value="Depo">
                </el-option>
                <el-option
                  key="Ofis"
                  label="Ofis"
                  value="Ofis">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="Aktif/Pasif">
            <el-switch v-model="form.status"> </el-switch>
          </el-form-item>
          <el-button style="width:100%" type="primary"><i class="el-icon-collection-tag"></i> İş Emri Oluştur</el-button>
          <br><br>
        </el-form>
      </div>
    </el-dialog>
    </div>
  </template>
  
  <script>
    export default{
        data(){
          return{
            specialField:false,
            form:{
              name:"",
              desc:'',
              area:'',
              type:'',
              status:true,
            },
            tableData:[
              {
                id:714911,
                status:1,
                area:"Depo",
                desc:'Dosya Taşıma A dan B ye',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                type:'Taşıma',
                priority:'Orta',
                from:'John Doe',
                to:'Mia Lee',
              },
              {
                id:714911,
                status:1,
                area:"Depo",
                desc:'Dosya Taşıma B den A ya',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                type:'Depo',
                priority:'Orta',
                from:'John Doe',
                to:'Mia Lee',
              },
              {
                id:714911,
                status:1,
                area:"Depo",
                desc:'#21334 Dosyanın imhası',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                type:'İmha',
                priority:'Orta',
                from:'John Doe',
                to:'Mia Lee',
              },
              {
                id:714911,
                status:1,
                area:"Depo",
                desc:'Dosya imzalaması',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                type:'Taşıma',
                priority:'Orta',
                from:'John Doe',
                to:'Mia Lee',
              },
            ]
          }
        }
    }
  </script>