import VueCookies from 'vue-cookies';
import router from '../../router';

const userStorageNamespace = process.env.VUE_APP_STORAGE_USER;
const tokenStorageNamespace = process.env.VUE_APP_STORAGE_TOKEN;
const userDetailStorageNamespace = process.env.VUE_APP_STORAGE_USER + '_detail';

const user = JSON.parse(localStorage.getItem(userStorageNamespace));
const user_detail = JSON.parse(localStorage.getItem(userDetailStorageNamespace));
const token = localStorage.getItem(tokenStorageNamespace);

import jwt_decode from "jwt-decode";

const state = {
  isLoggedIn: token ? true : false,
  user: user || null,
  token: token || null,
  isLoading: false,
  user_detail: user_detail || null,
}
const getters = {
	token(state) {
		return state.isLoggedIn && state.token ? state.token : null
  },
  isLoggedIn(state, getters) {
		return state.isLoggedIn && getters.jwtLoginStatus
  },
  status(state) {
    return state.isLoading
  },
  user(state) {
    return state.user
  },
  user_detail(state) {
    return state.user_detail
  },
  jwt(state){
    return jwt_decode(state.token);
  },
  jwtLoginStatus(state){
    const { exp } = jwt_decode(state.token)
    const now = Date.now() / 1000
    return now > exp ? false:true;
  }
}
const mutations = {
  AUTH_USER (state, payLoad) {
    state.user = payLoad
  },
  AUTH_USER_DETAIL (state, payLoad) {
    state.user_detail = payLoad
  },
  AUTH_LOGIN (state, payLoad) {
    state.isLoggedIn = payLoad
  },
  AUTH_LOGOUT (state) {
    state.isLoggedIn = false
    state.user = null
    state.token = null
  },
  AUTH_TOKEN (state, payLoad) {
    state.token = payLoad
  },
  AUTH_ISLOADING (state, payLoad) {
    state.isLoading = payLoad
  }
}
const actions = {
  logout: ({ commit, dispatch, getters }, payLoad) => {
    if(!payLoad) {
        dispatch('alert/message', { type: 'error', title: 'Çıkış Yapıldı'}, { root: true })
    }
    if(getters.isLoggedIn && getters.jwtLoginStatus){
      axios.post('/admin/auth/logout', payLoad).then((response) => {}).catch((c) => {})
    }
    localStorage.removeItem(userStorageNamespace);
    localStorage.removeItem(userDetailStorageNamespace);
    localStorage.removeItem(tokenStorageNamespace);

    window.axios.defaults.headers.common.Authorization = null
    commit('AUTH_LOGOUT')
    router.push({name: "Login"})
  },
  user({ commit, dispatch, state, getters}){

    if(getters.isLoggedIn || getters.user)
    {
      /* Check token time */
      const { exp } = getters.jwt
      const now = Date.now() / 1000 // exp is represented in seconds since epoch
      let timeUntilRefresh = exp - now
      timeUntilRefresh -= (15 * 60) // Refresh 15 minutes before it expires

      if(!getters.jwtLoginStatus){
        dispatch('logout', true);
        return;
      }

      if(timeUntilRefresh < 0){
        dispatch('refresh');
        return;
      }
      /* Check token time */
      axios.get('/admin/auth/user').then((response) => {
        if (response.data.status){

            localStorage.setItem(userStorageNamespace, JSON.stringify(response.data.data));
            commit('AUTH_USER', response.data.data)
            router.push({name: 'Homepage'});
        }
      })
      .catch((c) => {})
      .finally((f) => { 
          commit('AUTH_ISLOADING', false)
      })
    }
  },
  login ({ commit, dispatch }, payLoad)  {
      commit('AUTH_ISLOADING', true)
      axios.post('/admin/auth/login', payLoad).then((response) => {
          dispatch('token', response.data.data.token)
          commit('AUTH_LOGIN', true)
          dispatch('user')
          dispatch('alert/message', { type: 'success', title: 'Giriş Başarılı'}, { root: true })
      }).catch((c) => {
          commit('AUTH_ISLOADING', false)
      })
  },
  userInit({ commit}){
    axios.get('/admin/auth/user').then((response) => {
      if (response.data.status){
          localStorage.setItem(userStorageNamespace, JSON.stringify(response.data.data));
          commit('AUTH_USER', response.data.data)
      }
    })
  },
  refresh ({ commit, dispatch }, payLoad)  {
      axios.get('/admin/auth/refresh').then((response) => {
          if (response.data.status)
          {
              dispatch('token', response.data.data.token)
              dispatch('user')
          }
      }).catch((c) => {})
  },
  token: ({ commit, dispatch }, payLoad) => {
      window.axios.defaults.headers.common.Authorization = 'Bearer ' + payLoad
      localStorage.setItem(tokenStorageNamespace, payLoad);
      commit('AUTH_TOKEN', payLoad)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}