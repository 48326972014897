import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules';
//import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		langs:[],
	},
	getters:{
		getLangs(state){
			return state.langs
		}
	},
	mutations: {
		setLangs(state, val){
			state.langs = val
		},
	},
	actions: {
		initLangs({commit}){
			axios.get('admin/language/list').then((response) => {
				if (response.data.status){
					commit('setLangs', response.data.data)
				}
			})
		}
	},
	modules: modules
})
