import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FilterForm from './components/Common/FilterForm'
import ElementUI from "element-ui";
import locale from 'element-ui/lib/locale/lang/tr-TR'
import Notifications from 'vue-notification';
import VueMoment from 'vue-moment'
import CKEditor from 'ckeditor4-vue';


Vue.component('FilterForm', FilterForm);

Vue.use(ElementUI, { locale })

window.axios = require('axios');
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL; 
window.axios.defaults.headers.common = {
  'Accept': 'application/json',
  'Authorization': 'Bearer ' +  store.getters['auth/token']
};

window.axios.interceptors.response.use((response) => {
  return response
},
function (error) {
  if (error.response.status === 401) {
    store.dispatch('alert/alert', { type: 'error', title: error.response.data.message})
    store.dispatch('auth/logout', { silent: true })
  } else if (error.response.status === 422 || error.response.status === 429 || error.response.status === 403) {
    store.dispatch('alert/alert', { type: 'error', errors: error.response.data.errors, title: error.response.data.message });
  } else{
    store.dispatch('alert/alert', { type: 'error', title: 'whoops' });
  }
  return Promise.reject(error);
});

Vue.use(Notifications);
const moment = require('moment')
require('moment/locale/tr')
Vue.use(require('vue-moment'), {moment})
Vue.use( CKEditor );

window._ = require("lodash");
Vue.prototype._ = window._;

import './assets/scss/app.scss'

Vue.prototype.$user = function() {
  return store.getters['auth/user'];
}
Vue.prototype.$user_detail = function() {
    return store.getters['auth/user_detail'];
}
Vue.prototype.$auth = function() {
  return store.getters['auth/isLoggedIn'];
}

Vue.filter("currency", function(value) {
  var v = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return parseFloat(value) !== +value ? 0 : v;
});

Vue.filter('toDouble', function (value) {
    value = parseFloat(value).toFixed(2);
    return isNaN(value) ? 0 : value;
});

Vue.filter('ucFirst', function (value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});

Vue.filter('truncate', function (value, letter) {
    if (value.length > letter) {
        value = value.substring(0, letter - 3) + '...';
    }
    return value;
});
Vue.filter('toInt', function (value) {
    value = parseInt(value)
    return isNaN(value) ? 0 : value;
});

// MOBILE DETECT
import { isMobile } from 'mobile-device-detect';
Vue.prototype.isMobile = isMobile

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
