<template>
    <div>
      <el-button type="primary" @click="specialField = true" style="margin-bottom:10px"><i class="el-icon-collection-tag"></i> Yeni Özel Etiket Ekle</el-button>
      <el-card class="table-card">
        <h3 class="card-title">
          <div>
            <i class="el-icon-collection-tag"></i>   Özel Etiketler
          </div>
        </h3>
        <el-table
        :data="tableData"
        align="left"
        style="width: 100%">
        <el-table-column
          
          label="ID" width="100">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Özel Etiket Adı">
          <template slot-scope="scope">
            <i class="el-icon-document"></i> {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column
          label="Organizasyon">
          <template slot-scope="scope">
           {{ scope.row.organization }}
          </template>
        </el-table-column>
        <el-table-column
          label="Klasör">
          <template slot-scope="scope">
           {{ scope.row.folder }}
          </template>
        </el-table-column>
        <el-table-column
          label="Tür">
          <template slot-scope="scope">
            <i class="el-icon-position"></i> {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column
          label="Dosya Türleri">
          <template slot-scope="scope">
           {{ scope.row.file_type }}
          </template>
        </el-table-column>
        <el-table-column
          label="Tarih">
          <template slot-scope="scope">
           <i class="el-icon-date"></i> {{ scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column
          width="200" 
          label="Kullanılan Dosya Sayısı">
          <template slot-scope="scope">
            <i class="el-icon-document"></i>   {{ scope.row.total }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="Durumu">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="İşlem"
          width="120"
          >
          <template slot-scope="scope">
            <el-button style="width:100%" size="medium" type="danger" plain><i class="el-icon-delete"></i> Etiketi Sil</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    
    <el-dialog width="400px" title="Özel Alan Ekle" :visible.sync="specialField">
      <div>
        <el-form label-position="top">
          <el-form-item label="Dosya Adı">
            <el-input placeholder="Dosya adını yazınız" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Dosya Türü">
              <el-select v-model="form.type" placeholder="Select">
                <el-option
                  key="text"
                  label="text (Yazı ve Numara Barındırır)"
                  value="text">
                </el-option>
                <el-option
                  key="number"
                  label="number (Sadece Numara Barındırır)"
                  value="number">
                </el-option>
                <el-option
                  key="textarea"
                  label="textarea (Yazı ve Numara Cümle Barındırır)"
                  value="textarea">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="Klasör Seçimi">
            <el-cascader
              v-model="form.folder"
              :options="options"></el-cascader>
          </el-form-item>
          <el-form-item label="Organizasyon">
              <el-select placeholder="Select">
                <el-option
                  key="Eus Teknoloji"
                  label="Eus Teknoloji"
                  value="Eus Teknoloji">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="Eklenecek Türler">
              <el-select placeholder="Type">
                <el-option
                  key="PDF"
                  label="PDF"
                  value="PDF">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="Aktif/Pasif">
            <el-switch v-model="form.status"> </el-switch>
          </el-form-item>
          <el-button style="width:200px" type="primary"><i class="el-icon-collection-tag"></i> Özel Etiketi Oluştur</el-button>
          <br><br>
        </el-form>
      </div>
    </el-dialog>
    </div>
  </template>
  
  <script>
    export default{
        data(){
          return{
            specialField:false,
            form:{
              name:"",
              type:'number',
              status:false,
            },
            options: [ 
              {
                  value: 'Tüm Klasörler',
                  label: 'Tüm Klasörler',
                },
              {
                value: 'Marketing Materials',
                label: 'Marketing Materials',
                children: [{
                  value: 'Product Catalogs',
                  label: 'Product Catalogs',
                  children: [{
                    value: 'Human Resources',
                    label: 'Human Resources'
                  }, {
                    value: 'Project Planning',
                    label: 'Project Planning'
                  }, {
                    value: 'Performance Reviews',
                    label: 'Performance Reviews'
                  }, {
                    value: 'Press Releases',
                    label: 'Press Releases'
                  }]
                }, {
                  value: 'Website Analytics',
                  label: 'Website Analytics',
                  children: [{
                    value: 'Product Catalogs',
                    label: 'Product Catalogs'
                  }, {
                    value: 'Design Specs',
                    label: 'Design Specs'
                  }]
                }]
            }],
            tableData:[
              {
                id:1,
                status:1,
                name:"Fatura Numarası",
                type:'number',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                file_type:'PDF, DOC',
                folder:'Ana Dizin',
                total:4,
              },
              {
                id:2,
                status:1,
                name:"Dosya Depo No",
                type:'number',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                file_type:'PDF, DOC',
                folder:'Ana Dizin',
                total:4,
              },
              {
                id:3,
                status:1,
                name:"Klasör Numarası",
                type:'number',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                file_type:'PDF, DOC',
                folder:'Ana Dizin',
                total:4,
              },
              {
                id:4,
                status:1,
                name:"Özel Not",
                type:'textarea',
                date:'2022-12-2 13:11',
                organization:'Eus Teknoloji',
                file_type:'PDF, DOC',
                folder:'Ana Dizin',
                total:4,
              },
            ]
          }
        }
    }
  </script>