<template>
    <div>
      <el-card class="table-card">
        <h3 class="card-title">
          <div>
            <i class="el-icon-user"></i>   Organizasyonlar 
          </div>
        </h3>
        <el-row :gutter="5">
                <el-col :span="3">
                    <el-input size="medium" placeholder="ID Arama" v-model="form.name"></el-input>
                </el-col>
                <el-col :span="3">
                    <el-input size="medium" placeholder="Mail Arama" v-model="form.id"></el-input>
                </el-col>
                <el-col :span="3">
                    <el-input size="medium" placeholder="İsim Arama" v-model="form.name"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-button size="medium" type="primary" plain><i class="el-icon-search"></i> Ara</el-button>
                    <el-button size="medium" type="danger" plain><i class="el-icon-delete"></i> Temizle</el-button>
                </el-col>
        </el-row>
        <hr>
        <el-table
        :data="_.shuffle(tableData)"
        align="left"
        style="width: 100%">
        <el-table-column
          label="ID" width="100">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Adı">
          <template slot-scope="scope">
            <div class="flex align-center">
              {{ scope.row.organization }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Mail">
          <template slot-scope="scope">
            <i class="el-icon-message"></i>  {{ scope.row.mail }}
          </template>
        </el-table-column>
        <el-table-column
          label="Konumu">
          <template slot-scope="scope">
          <i class="el-icon-location"></i> İstanbul
          </template>
        </el-table-column>
        <el-table-column
          label="Tarih">
          <template slot-scope="scope">
           <i class="el-icon-date"></i> {{ scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="Durumu">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="İşlem"
          width="200"
          >
          <template slot-scope="scope">
            <el-button size="medium" @click="$router.push({name: 'OrganizationDetail', params: { id: scope.row.id, section: 'info'}})" type="default" plain><i class="el-icon-edit"></i> Düzenle</el-button>
            <el-button size="medium" type="danger" plain><i class="el-icon-remove"></i> Sil</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage4"
          :page-sizes="[10,20, 50, 75, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="20">
        </el-pagination>
      </div>
    </el-card>
    
    </div>
  </template>
  
  <script>
    export default{
        data(){
          return{
            specialField:false,
            form:{
              name:"",
              desc:'',
              name:'',
              type:'',
              status:true,
            },
            tableData:[
                {
                    id: 97,
                    status: 1,
                    name: "Jane Smith",
                    organization: "ABC Corporation",
                    mail: "info@abccorp.com",
                    location: "New York",
                    date: "2022-12-2 08:45"
                    },
                    {
                    id: 11,
                    status: 0,
                    name: "Bob Johnson",
                    organization: "XYZ Company",
                    mail: "info@xyz.com",
                    location: "Los Angeles",
                    date: "2022-11-1 14:23"
                    },
                    {
                    id: 54,
                    status: 1,
                    name: "Lisa Rodriguez",
                    organization: "Acme Industries",
                    mail: "info@acmeindustries.com",
                    location: "Miami",
                    date: "2022-10-5 10:10"
                    },
                    {
                    id: 56,
                    status: 1,
                    name: "Michael Lee",
                    organization: "Global Enterprises",
                    mail: "info@globent.com",
                    location: "London",
                    date: "2022-12-15 18:02"
                    },
                    {
                    id: 28,
                    status: 1,
                    name: "Sarah Brown",
                    organization: "Tech Innovations",
                    mail: "info@techinnov.com",
                    location: "San Francisco",
                    date: "2022-11-30 09:15"
                    },
                    {
                    id: 43,
                    status: 0,
                    name: "David Kim",
                    organization: "Big Corporation",
                    mail: "info@bigcorp.com",
                    location: "Seoul",
                    date: "2022-12-20 16:30"
                    },
                    {
                    id: 78,
                    status: 1,
                    name: "Emily Jones",
                    organization: "Innovate Solutions",
                    mail: "info@innovatesol.com",
                    location: "Sydney",
                    date: "2022-10-11 11:11"
                    },
                    {
                    id: 54,
                    status: 1,
                    name: "Daniel Smith",
                    organization: "Future Tech",
                    mail: "info@futuretech.com",
                    location: "Toronto",
                    date: "2022-11-24 13:45"
                    },
                {
                    id: 97,
                    status: 1,
                    name: "Jane Smith",
                    organization: "ABC Corporation",
                    mail: "info@abccorp.com",
                    location: "New York",
                    date: "2022-12-2 08:45"
                    },
                    {
                    id: 11,
                    status: 0,
                    name: "Bob Johnson",
                    organization: "XYZ Company",
                    mail: "info@xyz.com",
                    location: "Los Angeles",
                    date: "2022-11-1 14:23"
                    },
                    {
                    id: 54,
                    status: 1,
                    name: "Lisa Rodriguez",
                    organization: "Acme Industries",
                    mail: "info@acmeindustries.com",
                    location: "Miami",
                    date: "2022-10-5 10:10"
                    },
            ]
          }
        }
    }
  </script>