<template>
  <div>
    <el-row :gutter="15">
      <el-col :span="4">
        <el-card class="widget-card info">
          <div class="icon">
            <i class="el-icon-document"></i>
          </div>
          <div class="text">
            <div class="content">
               12
            </div>
            <span>Toplam Dosya</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card info">
          <div class="icon">
            <i class="el-icon-folder"></i>
          </div>
          <div class="text">
            <div class="content">
               7
            </div>
            <span>Toplam Klasör</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card success">
          <div class="icon">
            <i class="el-icon-document-checked"></i>
          </div>
          <div class="text">
            <div class="content">
               82
            </div>
            <span>Bugün Yüklenen Dosya</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card success">
          <div class="icon">
            <i class="el-icon-document-checked"></i>
          </div>
          <div class="text">
            <div class="content">
               82
            </div>
            <span>Bugün Erişilen Dosya</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card warning">
          <div class="icon">
            <i class="el-icon-loading"></i>
          </div>
          <div class="text">
            <div class="content">
               3
            </div>
            <span>OCR Bekleyen</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card warning">
          <div class="icon">
            <i class="el-icon-loading"></i>
          </div>
          <div class="text">
            <div class="content">
               2
            </div>
            <span>Thumbnail Bekleyen</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <br>
    <el-row :gutter="15">
      <el-col :span="4">
        <el-card class="widget-card danger">
          <div class="icon">
            <i class="el-icon-school"></i>
          </div>
          <div class="text">
            <div class="content">
               6
            </div>
            <span>Toplam Organizasyon</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card danger">
          <div class="icon">
            <i class="el-icon-s-custom"></i>
          </div>
          <div class="text">
            <div class="content">
               12
            </div>
            <span>Toplam Müşteri</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card success">
          <div class="icon">
            <i class="el-icon-user"></i>
          </div>
          <div class="text">
            <div class="content">
               26
            </div>
            <span>Aktif Kullanıcı</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card success">
          <div class="icon">
            <i class="el-icon-user"></i>
          </div>
          <div class="text">
            <div class="content">
               52
            </div>
            <span>Son 24 Saat Aktif Kullanıcı</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card purple">
          <div class="icon">
            <i class="el-icon-user"></i>
          </div>
          <div class="text">
            <div class="content">
               912
            </div>
            <span>Sistemdeki Kullanıcı</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="widget-card info">
          <div class="icon">
            <i class="el-icon-document"></i>
          </div>
          <div class="text">
            <div class="content">
               912
            </div>
            <span>Paylaşılan Dosya Sayısı</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <br>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-card  class="table-card">
            <h3 class="card-title">
              <span><i class="el-icon-document"></i>   Son Eklenen Dosyalar</span>

              <el-button @click="$router.push({ name: 'DocumentsDocument'})" type="primary" plain><i class="el-icon-search"></i> Tamamını Görüntüle</el-button>
            </h3>
            <el-table
            :data="_.shuffle(tableData)"
            align="left"
            style="width: 100%">
            <el-table-column
            label="Dosya Adı">
              <template slot-scope="scope">
                <div class="align-center">
                  <img style="margin-right:10px" width="25" :src="require('../assets/images/icons/format/' + scope.row.document_type + '.svg')">
                  <b>{{ scope.row.document_name }}</b>
                </div>
              </template>
            </el-table-column>
            <el-table-column
            width="150"
              label="Eklenme Tarihi">
              <template slot-scope="scope">
              <i class="el-icon-date"></i>   {{ scope.row.date_added }}
              </template>
            </el-table-column>
            </el-table>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card  class="table-card">
            <h3 class="card-title">
              <span> <i class="el-icon-share"></i>   Benimle Paylaşılan Dosyalar</span>
              <el-button @click="$router.push({ name: 'DocumentsDocument'})" type="primary" plain><i class="el-icon-search"></i> Tamamını Görüntüle</el-button>
            </h3>
            <el-table
            :data="_.shuffle(tableData)"
            align="left"
            style="width: 100%">
            <el-table-column
            label="Dosya Adı">
              <template slot-scope="scope">
                <div class="align-center">
                  <img style="margin-right:10px" width="25" :src="require('../assets/images/icons/format/' + scope.row.document_type + '.svg')">
                  <b>{{ scope.row.document_name }}</b>
                </div>
              </template>
            </el-table-column>
            <el-table-column
            width="150"
              label="Eklenme Tarihi">
              <template slot-scope="scope">
              <i class="el-icon-date"></i>   {{ scope.row.date_added }}
              </template>
            </el-table-column>
            </el-table>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card  class="table-card">
            <h3 class="card-title">
             <span> <i class="el-icon-view"></i>   Son Görüntülediğim Dosyalar</span>
              <el-button @click="$router.push({ name: 'DocumentsDocument'})" type="primary" plain><i class="el-icon-search"></i> Tamamını Görüntüle</el-button>
            </h3>
            <el-table
            :data="_.shuffle(tableData)"
            align="left"
            style="width: 100%">
            <el-table-column
            label="Dosya Adı">
              <template slot-scope="scope">
                <div class="align-center">
                  <img style="margin-right:10px" width="25" :src="require('../assets/images/icons/format/' + scope.row.document_type + '.svg')">
                  <b>{{ scope.row.document_name }}</b>
                </div>
              </template>
            </el-table-column>
            <el-table-column
            width="150"
              label="Eklenme Tarihi">
              <template slot-scope="scope">
              <i class="el-icon-date"></i>   {{ scope.row.date_added }}
              </template>
            </el-table-column>
            </el-table>
        </el-card>
      </el-col>
    </el-row>

    <br>
    
    <el-row :gutter="15">
      <el-col :span="8">
        <el-card  class="table-card">
            <h3 class="card-title">
              <span><i class="el-icon-user"></i>   Son Giriş Yapan Kullanıcılar</span>
              <el-button @click="$router.push({ name: 'UserList'})" type="primary" plain><i class="el-icon-search"></i> Tamamını Görüntüle</el-button>
            </h3>
            <el-table
            :data="_.shuffle(lastWork)"
            align="left"
            style="width: 100%">
            <el-table-column
              label="Üye">
              <template slot-scope="scope">
               <i class="el-icon-user"></i> {{ scope.row.username }}
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              label="Eklenme Tarihi">
              <template slot-scope="scope">
              <i class="el-icon-date"></i>   {{ scope.row.date }}
              </template>
            </el-table-column>
            </el-table>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card  class="table-card">
            <h3 class="card-title">
             <span> <i class="el-icon-loading"></i>  İşlem Bekleyen Dosyalar</span>
              <el-button @click="$router.push({ name: 'DocumentsDocument'})" type="primary" plain><i class="el-icon-search"></i> Tamamını Görüntüle</el-button>
            </h3>
            <el-table
            :data="_.shuffle(tableData)"
            align="left"
            style="width: 100%">
            <el-table-column
            label="Dosya Adı">
              <template slot-scope="scope">
                <div class="align-center">
                  <img style="margin-right:10px" width="25" :src="require('../assets/images/icons/format/' + scope.row.document_type + '.svg')">
                  <b>{{ scope.row.document_name }}</b>
                </div>
              </template>
            </el-table-column>
            <el-table-column
            width="150"
              label="Eklenme Tarihi">
              <template slot-scope="scope">
              <i class="el-icon-date"></i>   {{ scope.row.date_added }}
              </template>
            </el-table-column>
            </el-table>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card  class="table-card">
            <h3 class="card-title">
              <div><span><i class="el-icon-loading"></i>   Bekleyen İş Emirleri</span></div>
              <el-button @click="$router.push({ name: 'WorkOrder'})" type="primary" plain><i class="el-icon-search"></i> Tamamını Görüntüle</el-button>
            </h3>
            <el-table
            :data="_.shuffle(lastWork)"
            align="left"
            style="width: 100%">
            <el-table-column
              label="Üye">
              <template slot-scope="scope">
               <i class="el-icon-user"></i> {{ scope.row.username }}
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              label="Tür">
              <template slot-scope="scope">
                {{ scope.row.type }}
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              label="Eklenme Tarihi">
              <template slot-scope="scope">
              <i class="el-icon-date"></i>   {{ scope.row.date }}
              </template>
            </el-table-column>
            </el-table>
        </el-card>
      </el-col>
    </el-row>

    
    <br>
    
    <el-row :gutter="10">
      <el-col :xs="24" :span="8">
        <el-card class="table-card">
            <h3 class="card-title">
              <span><i class="el-icon-document"></i>   Haftalık Eklenen Dosyalar</span>
            </h3>
              <bar-chart :options="options" :height="175" :chart-data="chargeData"></bar-chart>
        </el-card>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-card class="table-card">
              <h3 class="card-title">
                <span><i class="el-icon-document"></i>   Haftalık Yeni Kullanıcılar</span>
              </h3>
              <bar-chart :options="options" :height="175" :chart-data="chargeData2"></bar-chart>
        </el-card>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-card class="table-card">
              <h3 class="card-title">
                <span><i class="el-icon-document"></i>   Haftalık Saate Göre Dosya Yükleme</span>
              </h3>
              <line-chart :options="options" :height="175" :chart-data="chargeData3"></line-chart>
        </el-card>
      </el-col>
    </el-row>
    
  </div>
</template>

<script>
  import LineChart from '../components/Charts/Line.js'
  import BarChart from '../components/Charts/Bar.js'
  export default{
    data(){
      return{
        loading: false,
        options: {
            legend: {
                display: false
            },
        },
        showFiles:null,
        chargeData: [],
        data:[],
        lastWork:[
          { username: 'johndoe', type: 'İmha', date: '2022-01-10 13:00'},
          { username: 'johndoe', type: 'Taşıma', date: '2022-01-10 13:00'},
          { username: 'johndoe', type: 'Değiştirme', date: '2022-01-10 13:00'},
          { username: 'johndoe', type: 'Düzenleme', date: '2022-01-10 13:00'},
          { username: 'johndoe', type: 'İmha', date: '2022-01-10 13:00'},
        ],
        lastUsers:[
          { username: 'johndoe', date: '2022-01-10 13:00'},
          { username: 'johndoe', date: '2022-01-10 13:00'},
          { username: 'johndoe', date: '2022-01-10 13:00'},
          { username: 'johndoe', date: '2022-01-10 13:00'},
          { username: 'johndoe', date: '2022-01-10 13:00'},
        ],
        tableData: [
              {
                "id":14562,
                "status":1,
                "status_name":"completed",
                "folder":'Project Files',
                "document_name": "project_planning_report_14562.doc",
                "date_added": "2022-02-12 13:00",
                "document_type": "doc",
                "uploader_name": "Ali Can"
              },
              {
                "id":22453,
                "status":2,
                "status_name":"completed",
                "folder":'Marketing Campaign',
                "document_name": "accounting_management_guide_22453.xls",
                "date_added": "2022-03-15 13:00",
                "document_type": "xls",
                "uploader_name": "Ayse Yilmaz"
              },
              {
                "id":33223,
                "status":2,
                "status_name":"completed",
                "folder":'Invoices',
                "document_name": "budget_presentation_33223.docx",
                "date_added": "2022-01-10 13:00",
                "document_type": "docx",
                "uploader_name": "Mehmet Yildiz"
              },
              {
                "id":45632,
                "status":2,
                "status_name":"completed",
                "folder":'Product Design',
                "document_name": "new_hiring_procedure_45632.doc",
                "date_added": "2022-02-20 13:00",
                "document_type": "doc",
                "uploader_name": "Fatma Aktas"
              },
              {
                "id":63421,
                "status":2,
                "status_name":"completed",
                "folder":'Customer Feedback',
                "document_name": "equipment_maintenance_instructions_63421.pdf",
                "date_added": "2022-03-05 13:00",
                "document_type": "pdf",
                "uploader_name": "Ahmet Yilmaz"
              },
        ],
      }
    },
    created(){
      this.fetch()
      this.fillData()
    },
    mounted () {
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    components: {
      LineChart,BarChart
    },
    methods: {
      fillData () {
        let randomNumbers = Array.from({length: 24}, () => Math.floor(Math.random() * 24));
        this.chargeData = {
          labels: [...Array(24).keys()],
          datasets: [
            {
              backgroundColor: Array.from({length: 24}, () => '#'+(Math.random()*0xFFFFFF<<0).toString(16)),
              fill: false,
              data: randomNumbers
            }
          ]
        }
        this.chargeData2 = {
          labels: [...Array(24).keys()],
          datasets: [
            {
              backgroundColor: Array.from({length: 24}, () => '#'+(Math.random()*0xFFFFFF<<0).toString(16)),
              fill: false,
              data: randomNumbers
            }
          ]
        }
        this.chargeData3 = {
          labels: [...Array(5).keys()],
          datasets: [
            {
              borderColor: ['#67c23a'],
              fill: false,
              data: randomNumbers
            }
          ]
        }
      },
      getRandomInt () {
        return Math.floor(Math.random() * (123 - 5 + 1)) + 5
      },
      fetch(){
        this.loading = true;
        axios.get('/admin')
        .then((response) => {
            this.data = response.data;
            this.data.customer.online = _.orderBy(_.values(this.data.customer.online), [item => item.last_seen_at], ['desc']); 
            
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        });
      },
    }
  }
</script>