<template>
  <div class="login-form">
    <div class="login">
      <h2 class="logo"><img src="../../assets/images/logo.png" width="150"></h2>
      <el-form label-position="top">
        <el-form-item>
          <template slot="label"><i class="el-icon-user"></i> Kullanıcı Adınız</template>
          <el-input v-model="form.username" placeholder="Kullanıcı adınızı yazınız"></el-input>
        </el-form-item>
        <el-form-item @keyup.enter.native="handleLogin">
          <template slot="label"><i class="el-icon-lock"></i> Şifreniz</template>
          <el-input type="password" placeholder="Şifrenizi yazınız" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="isLoading" @click="handleLogin" style="width:100%;" type="primary">
            <i class="el-icon-d-arrow-right"></i>
            <b> Giriş Yap</b></el-button>
        </el-form-item>
        <router-link class="el-link el-link--primary is-underline" :to="{ name: 'ForgotPassword' }">
          <i class="el-icon-question"></i>
          Şifremi Unuttum</router-link>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  export default{
    name: 'Login',
    data(){
      return{
        form:{
          username:null,
          password:null,
        }
      }
    },
    computed:{
      ...mapState("auth", ["isLoading"])
    },
    methods:{
      ...mapActions("auth", ["login"]),
      handleLogin() {
        this.login(this.form)
      }
    },
  }
</script>
